<template>
  <v-card class="ma-2 pa-0 mt-5">
    <v-container>
      <v-row class="justify-center">
        <v-col cols="10">
          <v-row align="center" class="pl-7">
            <v-col cols="2">受付窓口</v-col>
            <v-col cols="9" class="ml-4 d-flex">
              <v-checkbox
                v-for="state in serveFlagStateList"
                :key="state.value"
                :label="state.label"
                :value="state.value"
                v-model="serveFlagState"
                hide-details
                class="mr-3 my-0"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="pl-7">
            <v-col cols="2" class="mt-2">
              <p class="mb-0 text-subtitle-1">集計用</p>
            </v-col>
            <v-col class="ml-4"
              cols="4"
            >
              <v-text-field v-model="aggregateTypesName" maxlength="255" outlined dense></v-text-field>
            </v-col>
            <v-col
              slot="header"
              class="btn-group"
              role="group"
              key="footer"
            >
              <v-btn color="primary" :disabled="aggregateTypesName == '' || serveFlagState.length == 0" @click="add">新規</v-btn>
            </v-col>
          </v-row>
          <draggable tag="ul" :list="aggregateTypesList" @end="endDraging" @change="reOder">
            <transition-group>
              <v-col
                class="list-group-item"
                v-for="(aggregateTypes, idx) in aggregateTypesList"
                :key="aggregateTypes.id"
              >
                <v-col>
                  <v-icon class="handle">mdi-drag</v-icon>

                  <v-col class="d-inline" cols="3">
                    <span>{{aggregateTypes.name}}</span>
                  </v-col>
                </v-col>

                 <v-col>
                   <v-btn class="float-end" color="warning" @click="removeAt(idx,aggregateTypes.id)">削除</v-btn>
                   <v-btn class="float-end mr-2" color="primary" @click="edit(aggregateTypes.id,aggregateTypes.name,aggregateTypes.server_type)">編集</v-btn>
                 </v-col>

              </v-col>
            </transition-group>
          </draggable>

        </v-col>

      </v-row>

      <v-row justify="center">
        <v-dialog
          v-model="editDialog"
          persistent
          max-width="900"
        >
          <v-card>
            <v-toolbar color="primary" dark>
              <span class="text-h5 ml-3">集計用マスタ表示</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="editDialog = false">
                <v-icon large>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row align="center" class="mt-4">
                  <v-col cols="2">受付窓口</v-col>
                  <v-col cols="10" class="px-0 d-flex">
                    <v-checkbox
                      v-for="state in serveFlagStateList"
                      :key="state.value"
                      :label="state.label"
                      :value="state.value"
                      v-model="serveFlagStateChange"
                      hide-details
                      class="mr-3 my-0"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row align="center" class="mt-4">
                  <v-col cols="2" class="pt-0">
                    <p class="text-subtitle-1">集計用</p>
                  </v-col>
                  <v-col cols="9" class="px-0">
                    <v-text-field maxlength="255" outlined dense v-model="newNameChange"></v-text-field>
                  </v-col>
                </v-row>
              <v-row justify="center">
                <v-btn :disabled="!canEdit || newNameChange == ''"
                       rounded
                       color="primary"
                       @click="updateAggregateTypes(aggregateTypesEdit.id,newNameChange,serveFlagStateChange)"
                       x-large class="mb-3"
                >保存</v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- dialog Confrim Delete -->
      <v-dialog
        v-model="dialogConfirmDelete"
        persistent
        overflow-y=initial
        width=500px
      >
        <v-card>
          <v-card-text>
            <v-container fluid>
              <v-row class="pl-0">
                <v-col cols="12" class="ml-0 px-0 pb-0">
                  <v-card-title class="headline">削除してもよろしいでしょうか。</v-card-title>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <template>
              <v-btn color="primary" @click="()=> dialogConfirmDelete = false" style="width: 120px;">キャンセル</v-btn>
            </template>
            <template>
              <v-btn color="primary" @click="removeAt(aggregateDelete.id, aggregateDelete.subId, true)" style="width: 120px;">削除</v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-snackbar v-model="noti.success"
                :timeout="3000"
                absolute
                bottom
                multi-line
                color="success"
                right>
      <strong><v-icon>mdi-check</v-icon>
        {{noti.notiText}}</strong>
    </v-snackbar>
    <v-snackbar v-model="noti.error"
                :timeout="3000"
                absolute
                bottom
                multi-line
                color="red accent-2"
                right>
      <strong><v-icon>mdi-close</v-icon>
        {{noti.notiText}}</strong>
    </v-snackbar>

  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import aggregateTypesServices from "@/services/aggregateTypesServices";

export default {
  components: {
    draggable
  },
  data() {
    return {
      order: 0,
      id: 0,
      aggregateTypesName: '',
      aggregateTypesList: [],
      dragging: false,
      editDialog: false,
      canEdit: false,

      // notify
      noti: {
        error: false,
        success: false,
        notiText: '',
      },

      // edit
      newNameChange: '',
      aggregateTypesEdit: [
        {
          id: null,
          name: '',
        }
      ],
      serveFlagStateList: [
        { value: 0, label: 'お客様相談室' },
        { value: 1, label: 'オフィシャルショップ' },
        { value: 2, label: 'Shachihata ID' },
        { value: 3, label: 'メールオーダー' },
      ],
      serveFlagStateChange: [],
      serveFlagState: [0],
      dialogConfirmDelete: false,
      aggregateDelete: [
        {
          id: null,
          subId: null
        }
      ],
    };
  },
  mounted() {
    aggregateTypesServices.getAggregateTypesList().then(Response=>{
      this.aggregateTypesList = Response.data.data;
    });
  },
  methods: {
    reOder() {
      const newAggregateTypesList = [...this.aggregateTypesList].map((item,index)=>{
        const newOrder = index + 1;
        item.hasChanged = item.sortOrder !== newOrder;
        if(item.hasChanged){
          item.sortOrder = newOrder;
        }
        return item;
      });
      this.aggregateTypesList = newAggregateTypesList;
    },
    async endDraging() {
      let params = this.aggregateTypesList;
      aggregateTypesServices.orderUpdate(params).then(Response=>{
        this.aggregateTypesList = Response.data.data;
      });
    },
    async reloadList() {
      aggregateTypesServices.getAggregateTypesList().then(Response=>{
        this.aggregateTypesList = Response.data.data;
      })
    },
    async removeAt(idx, subId, actionDelete = false) {
      if (actionDelete) {
        aggregateTypesServices.deleteAggregateTypes(subId).then(()=>{
          this.aggregateTypesList.splice(idx, 1);
          this.aggregateDelete.id = null;
          this.aggregateDelete.subId = null;
          this.dialogConfirmDelete = false;
        }).catch(() => {
          this.dialogConfirmDelete = false;
          this.reloadList();
        });
      } else {
        this.aggregateDelete.id = idx;
        this.aggregateDelete.subId = subId;
        this.dialogConfirmDelete = true;
      }
    },
     async add() {
      if(this.aggregateTypesName != '' && this.serveFlagState.length > 0){
        this.order++;
          let params = {
          name: this.aggregateTypesName,
          order: this.order,
          server_type: this.serveFlagState,
        }
        aggregateTypesServices.createAggregateTypes(params).then(()=>{
          this.reloadList();
          this.aggregateTypesName = '';
          this.serveFlagState = [0];
        })
      }
    },
    edit(subId, subName, serverAggregateTypesFlg){
      serverAggregateTypesFlg = serverAggregateTypesFlg ? [...serverAggregateTypesFlg].map(Number).filter(num => !isNaN(num)) : [];
      this.aggregateTypesEdit.id = subId;
      this.aggregateTypesEdit.name = subName;
      this.aggregateTypesEdit.server_type = serverAggregateTypesFlg;
      this.serveFlagStateChange = serverAggregateTypesFlg;
      this.newNameChange = subName;
      this.editDialog = true;
    },
    async updateAggregateTypes(id, name, serverAggregateTypes){
      let params = {name: name, server_type: serverAggregateTypes};
      aggregateTypesServices.editAggregateTypes(id,params).then(()=>{
        this.editDialog = false;
        this.newNameChange = '';
        this.aggregateTypesEdit.name = '';
        this.aggregateTypesEdit.id = null;
        this.serveFlagStateChange = [0];
        this.aggregateTypesEdit.server_type = [];
        this.reloadList();
      }).catch(()=>{
        this.editDialog = false;
        this.newNameChange = '';
        this.aggregateTypesEdit.name = '';
        this.aggregateTypesEdit.id = null;
        this.serveFlagStateChange = [0];
        this.aggregateTypesEdit.server_type = [];
        this.reloadList();
      });
    },
    isArraysDifferent() {
      const serveFlagStateChange = [...this.serveFlagStateChange].sort();
      const server_type = [...this.aggregateTypesEdit.server_type].sort();
      if (serveFlagStateChange.length !== server_type.length) {
        return false;
      }
      return serveFlagStateChange.every((value, index) => value === server_type[index]);
    },
  },

  watch:{
    aggregateTypesList(){
      if(this.aggregateTypesList.length == 0){
        this.order = 0;
        this.id = 0;
      } else {
        this.order = Math.max.apply(Math, this.aggregateTypesList.map(function (aggregateTypesList){
          return aggregateTypesList.order;
        }));

        this.id = Math.max.apply(Math, this.aggregateTypesList.map(function (aggregateTypesList){
          return aggregateTypesList.id;
        }));
      }
    },
    newNameChange(){
      if(this.serveFlagStateChange.length == 0
        || this.newNameChange == null
        || this.newNameChange == this.aggregateTypesEdit.name
      ){
        this.canEdit = false;
      } else this.canEdit = true;
    },
    serveFlagStateChange(){
      if(this.serveFlagStateChange.length == 0
        || this.newNameChange == null
        || this.isArraysDifferent()
      ){
        this.canEdit = false;
      } else this.canEdit = true;
    }
  }
};
</script>
<style scoped>

.handle {
  float: left;
}
input {
  display: inline-block;
  width: 50%;
}

.list-group-item{
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0px;
}
.list-group-item > div {
  padding: 6px;
}
.list-group-item:hover{
  background-color: #d29e1921;
}
</style>
