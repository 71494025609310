<template>
  <v-card color="grey lighten-5" class="my-3 mx-5">
    <template>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              文書名
            </th>
            <th class="text-left">
              作成日
            </th>
            <th class="text-left">
              ステータス
            </th>
            <th class="text-left">
              利用システム
            </th>
            <th class="text-center">
              削除
            </th>
          </tr>
          </thead>
          <!-- popup delete-->
          <template>
            <v-row justify="center">
              <v-dialog
                v-model="dialogConfirmDelete"
                persistent
                max-width="500"
              >
                <v-card>
                  <v-card-title class="text-h6">
                    削除してもよろしいですか。
                  </v-card-title>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue-grey darken-1"
                      rounded
                      dark
                      @click="dialogConfirmDelete = false"
                    >
                      <v-icon dark>
                        mdi-close-octagon
                      </v-icon>
                      キャンセル
                    </v-btn>
                    <v-btn 
                      color="error"
                      rounded
                      @click="deleteProductUploadYes"
                    >
                      <v-icon dark>
                        mdi-check
                      </v-icon>
                      削除
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
          <tbody>
          <tr v-for="productUpload in productUploadList" :key="productUpload.id">
            <td class="font-weight-bold" style="word-break: break-all;">{{ productUpload.document_name }}</td>
            <td>{{productUpload.created_at}}</td>
            <td v-if="productUpload.status == 0">処理開始</td>
            <td v-else-if="productUpload.status == 1">処理待ち</td>
            <td v-else-if="productUpload.status == 2">正常完了 </td>
            <td class="red--text" v-else>異常終了</td>
            <td>{{ productUpload.name_main }}</td>
            <td>
              <div class="d-flex justify-center">
                <v-btn color="error" depressed @click="confirmBeforeDelete(productUpload.id, productUpload.import_type)">削除</v-btn>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-if="productUploadList.length < 1" class="text-center">データがありません</div>
    </template>
    <v-overlay :value="loadingCount > 0">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>

import productUploadService from "@/services/productUploadService";
import importUploadService from "@/services/importUploadService";

export default {
  props:{
    productUploadList: Array,
  },

  data() {
    return {
      loadingCount: 0,
      dialogConfirmDelete: false,
      productUploadId: null,
      importUploadType: null,
    }
  },
  methods:{
    confirmBeforeDelete(productUploadId, importType) {
      this.productUploadId = productUploadId;
      this.dialogConfirmDelete = true;
      this.importUploadType = importType;
    },

    async deleteProductUploadYes() {
      this.dialogConfirmDelete = false;
      this.loadingCount++;
      try {
        if (this.importUploadType === 1) {
          await importUploadService.deleteImportUpload(this.productUploadId);
        } else {
          await productUploadService.deleteProductUpload(this.productUploadId);
        }

        this.$emit('deleteProductUpload');
      } catch (error) {
        console.error("Error deleting upload:", error);
      } finally {
        this.loadingCount--;
      }
    },
  },
}
</script>
<style scoped>
/* テーブルのヘッダーのフォントサイズ */
.v-data-table ::v-deep th {
  font-size: 18px !important;
  background-color: #90caf9;
}
/* テーブルデータのフォントサイズ */
.v-data-table ::v-deep td {
  /* font-size: 10px !important; */
  background-color: #e3f2fd;
}
.v-data-table ::v-deep tr:nth-child(odd) td {
  background: #fff;
}

.v-data-table ::v-deep tr:hover td {
  background-color: #eee;
}
::v-deep .v-data-table__empty-wrapper > td {
  font-size: 20px !important;
  /* font-family: sans-serif !important; */
}
.v-dialog__content{
  top: -200px;
}
</style>
