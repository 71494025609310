import http from '@/httpCommon.js';

export default {
  async createAggregateTypes(data) {
    return http.post(`/aggregate-types`, data,{ headers: {enableMessage: true}});
  },
  async getAggregateTypesList(){
    return http.get('/aggregate-types',{ headers: {enableMessage: false}});
  },
  async deleteAggregateTypes(subId){
    return http.delete(`/aggregate-types/${subId}`,{ headers: {enableMessage: true}});
  },
  async editAggregateTypes(subId,name){
    return http.put(`/aggregate-types/${subId}`,name,{ headers: {enableMessage: true}});
  },

  async orderUpdate(dataUpdate){
    return http.put('/aggregate-types-order',dataUpdate,{ headers: {enableMessage: true}});
  }

};
