<template>
  <div style="background-color: #E0E0E0; height: 100%; padding-top: 1px;">
    <v-card color="grey lighten-5" class="ma-3">
      <v-card-title class="py-1">お客様対応検索</v-card-title>
      <v-divider class="mx-4"></v-divider>

      <v-row class="pt-3" align="center" no-gutters>
        <v-col cols="2" class="mr-1">
          <p class="text-center text-subtitle-1 mb-0 float-left pl-5">表示期間</p>
        </v-col>
        <v-col cols="2" class="pr-5">
          <v-text-field
            type="date"
            v-model="periodStartDate"
            label="開始日"
            class="pa-0 ma-0"
            :disabled="noDateSpecified"
            outlined
            dense
            hide-details
            @keyup.enter="beforeSearchMatterList"
          />
        </v-col>
        <v-col cols="1">
          <p class="text-center text-h5 mb-0">～</p>
        </v-col>
        <v-col cols="2" class="pr-5">
          <v-text-field
            type="date"
            v-model="periodEndDate"
            label="終了日"
            class="pa-0 ma-0"
            :disabled="noDateSpecified"
            outlined
            dense
            hide-details
            @keyup.enter="beforeSearchMatterList"
          />
        </v-col>

        <v-col cols="4">
          <v-checkbox
            ref="noDateSpecified"
            v-model="noDateSpecified"
            label="日付指定なし"
            class="d-inline-flex mini-size"
            dense
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- お客様名 -->
      <v-row class="pt-3" align="center" no-gutters>
        <v-col cols="2" class="mr-1">
          <p class="text-center text-subtitle-1 mb-0 float-left pl-5">分類から絞り込み</p>
        </v-col>
        <v-col cols="2" class="pr-5">
          <v-select v-model="classification_l_code"
            :items="classification_l"
            item-text="classification_name"
            item-value="classification_code"
            label="大分類"
            outlined
            hide-details
            dense
            clearable
            no-data-text="データがありません"
            @keyup.enter="beforeSearchMatterList"
          ></v-select>
        </v-col>
        <v-col cols="2" class="pr-5">
          <v-select v-model="classification_m_code"
            :items="classification_m"
            item-text="classification_name"
            item-value="classification_code"
            label="中分類"
            outlined
            no-data-text="データがありません"
            hide-details
            dense
            clearable
            @keyup.enter="beforeSearchMatterList"
          ></v-select>
        </v-col>
        <v-col cols="2" class="pr-5">
          <v-select v-model="classification_s_code"
            :items="classification_s"
            item-text="classification_name"
            item-value="classification_code"
            label="小分類"
            outlined
            hide-details
            dense
            no-data-text="データがありません"
            clearable
            @keyup.enter="beforeSearchMatterList"
          ></v-select>
        </v-col>

        <v-col cols="3" class="pr-5">
          <v-select v-model="product_choose"
            :items="product_data"
            item-text="product_name"
            label="商品一覧"
            :item-value="(item) => item"
            outlined
            multiple
            :menu-props="{auto: false, maxHeight: 730}"
            hide-details
            dense
            no-data-text="データがありません"
            clearable
            @click:clear="product_list_code = []"
            @keyup.enter="beforeSearchMatterList"
          >

            <template v-slot:prepend-item>

              <v-list-item
                ripple
                @mousedown.prevent
                @click="itemToggle"
              >
                <v-list-item-action>
                  <v-icon :color="product_choose.length > 0 ? 'primary' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    すべて選択
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field v-model="product_item_search" placeholder="検索" @input="searchProduct"></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>



            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.product_name }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
          (+{{ product_choose.length - 1 }} 商品)
        </span>
            </template>
          </v-select>
        </v-col>

      </v-row>

      <v-row class="pt-3" align="center" no-gutters>
        <v-col cols="2" class="mr-1" >
          <p class="text-center text-subtitle-1 mb-0 float-left  pl-5">商品マスターから抽出</p>
        </v-col>
        <v-col cols="2" class="pr-5">
          <v-btn
            rounded
            color="success"
            class="mb-2 mr-3"
            @click="searchConditionsModalOpen"
            style="width: 125px;"
          >商品検索</v-btn>
        </v-col>
      </v-row>

      <v-row class="pt-0" no-gutters>
        <v-col cols="6" class="pr-5">
          <v-row class="pt-3" align="center" no-gutters>
            <v-col cols="4" class="mr-1" >
              <p class="text-center text-subtitle-1 mb-0 float-left  pl-5">小区分</p>
            </v-col>
            <v-col cols="4" class="px-2">
              <v-select
                v-model="subtype_id"
                :items="filteredSubTypeList"
                item-text="name"
                item-value="id"
                label="小区分"
                outlined
                hide-details
                dense
                no-data-text="データがありません"
                clearable
                @keyup.enter="beforeSearchMatterList"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="pt-3" align="center" no-gutters>
            <v-col cols="4" class="mr-1" >
              <p class="text-center text-subtitle-1 mb-0 float-left  pl-5">集計用</p>
            </v-col>
            <v-col cols="4" class="px-2">
              <v-select
                v-model="aggregateType_id"
                :items="filteredAggregateTypesList"
                item-text="name"
                item-value="id"
                label="集計用"
                outlined
                hide-details
                dense
                no-data-text="データがありません"
                clearable
                @keyup.enter="beforeSearchMatterList"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="pt-3" align="center" no-gutters>
            <v-col cols="4" class="mr-1" >
              <p class="text-center text-subtitle-1 mb-0 float-left  pl-5">受付窓口</p>
            </v-col>
            <v-col cols="4" class="px-2">
              <v-select
                v-model="server_id"
                :items="serverList"
                item-text="label"
                item-value="value"
                outlined
                hide-details
                dense
                @change="handleServeFlagChange"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" class="d-flex justify-end align-end">
          <v-card elevation-24 :class="[this.isDefectRequestSearch == 'defect' ? 'border-color':'', 'dash-item']" style="background: #faebd7; width: 145px !important;">
            <v-card-text class="fs-1">
              <b>不良・不具合</b>
            </v-card-text>
            <v-divider></v-divider>
            <v-btn @click="matterSearchByDefectRequest('defect',true)" block class="text-center fs-2" style="background-color: inherit;height: 60px; width: 100%">{{matters_count.defectFlg}}件</v-btn>
          </v-card>
          <v-card elevation-24 :class="[this.isDefectRequestSearch == 'request' ? 'border-color':'', 'dash-item']" style="background: inherit">
            <v-card-text class="fs-1">
              <b>要望</b>
            </v-card-text>
            <v-divider></v-divider>
            <v-btn @click="matterSearchByDefectRequest('request',true)" block class="text-center fs-2" style="background-color: inherit;height: 60px; width: 100%">{{matters_count.requestFlg}}件</v-btn>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="pt-3 d-flex" style="position: relative" align="center" no-gutters>
          <v-col cols="2" class="mr-1">
            <p class="text-center text-subtitle-1 mb-0 float-left pl-5" style="position: absolute; top: 1.3rem">選択した商品</p>
          </v-col>
        <v-col cols="3.5" class="mr-4">
          <v-textarea v-model="product_data_name"
                      rows="3"
                      outlined
                      dense
                      readonly
          >
          </v-textarea>
        </v-col>
        <v-col cols="6" class="d-flex dash justify-end">
          <v-card elevation-24 :class="[this.categoryFlg === 1 ? 'border-color':'', 'dash-item']" style="background: #faebd7">
            <v-card-text class="fs-1">
              <b>お問合せ</b>
            </v-card-text>
            <v-divider></v-divider>
            <v-btn @click="matterSearchByCategory(1,true)" block class="text-center fs-2" style="background-color: inherit;height: 60px; width: 100%">{{matters_count.categoryFlg1}}件</v-btn>
          </v-card>
          <v-card elevation-24 :class="[this.categoryFlg === 2 ? 'border-color':'', 'dash-item']" >
            <v-card-text class="fs-1">
              <b>苦情</b>
            </v-card-text>
            <v-divider></v-divider>
            <v-btn @click="matterSearchByCategory(2,true)" block class="text-center fs-2" style="background-color: inherit;height: 60px; width: 100%">{{matters_count.categoryFlg2}}件</v-btn>
          </v-card>
          <v-card elevation-24 :class="[this.categoryFlg === 3 ? 'border-color':'', 'dash-item']" style="background: #afeeee">
            <v-card-text class="fs-1">
              <b>ご意見</b>
            </v-card-text>
            <v-divider></v-divider>
            <v-btn @click="matterSearchByCategory(3,true)" block class="text-center fs-2" style="background-color: inherit;height: 60px; width: 100%">{{matters_count.categoryFlg3}}件</v-btn>
          </v-card>
          <v-card elevation-24 :class="[this.categoryFlg === 4 ? 'border-color':'', 'dash-item']" style="background: #e6e6fa">
            <v-card-text class="fs-1">
              <b>その他</b>
            </v-card-text>
            <v-divider></v-divider>
            <v-btn @click="matterSearchByCategory(4,true)" block class="text-center fs-2" style="background-color: inherit;height: 60px; width: 100%">{{matters_count.categoryFlg4}}件</v-btn>
          </v-card>
          <v-card elevation-24 :class="[this.categoryFlg === null ? 'border-color':'', 'dash-item']" style="background: #a3c8e7">
            <v-card-text class="fs-1">
              <b>合計</b>
            </v-card-text>
            <v-divider></v-divider>
            <v-btn @click="matterSearchByCategory(null,true)" block class="text-center fs-2" style="background-color: inherit;height: 60px; width: 100%">{{matters_count.matters}}件</v-btn>
          </v-card>
        </v-col>
      </v-row>

      <!-- 検索ボタン -->
      <v-row no-gutters>
        <v-col class="d-flex justify-start ml-5">
          <v-btn @click="prepareDownloading = true"
            rounded
            elevation="5"
            width="200"
            color="primary"
            class="white--text"
          >一覧表示出力
          </v-btn>
        </v-col>
        <template>
          <v-row justify="center" style="position: absolute">
            <v-dialog
              v-model="prepareDownloading"
              persistent
              max-width="500"
            >
              <v-card>
                <v-card-title class="text-h6">
                  選択文書ダウンロード予約
                </v-card-title>
                <v-text-field class="mx-5" v-model="fileName"
                  label="ファイル名"
                  placeholder="拡張子含め50文字まで。拡張子は自動付与されます。"
                  outlined dense
                  :hint="fileName.length > 0 ? fileName.replaceAll(' ','')+'.csv' : ''"
                ></v-text-field>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-grey darken-1"
                    rounded
                    dark
                    @click="closeDownloadCSV"
                  >
                    <v-icon dark>
                      mdi-close-octagon
                    </v-icon>
                    キャンセル
                  </v-btn>
                  <v-btn
                    color="success"
                    rounded
                    @click="confirmDownload"
                    :disabled="fileName.length <= 0 || fileName.length > 50"
                  >
                    <v-icon dark>
                      mdi-download
                    </v-icon>
                    はい
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
        <v-col class="d-flex justify-center">
          <v-btn
            rounded
            elevation="5"
            width="200"
            color="success"
            class="white--text"
            @click="beforeSearchMatterList"
          >
            上記の条件で検索
          </v-btn>
        </v-col>
        <v-col class="d-flex justify-end mr-5">
          <v-btn
            @click="resetDataSearch"
            rounded
            elevation="5"
            width="200"
            color="warning"
            class="white--text"
          >
            検索条件クリア
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="mt-3 mx-4"></v-divider>
      <!-- 問い合せ一覧テーブル表示 -->
      <all-matter-search-table
        :matter-list="matterList" :matter_headers="matterHeaders" @saveSettings="updateHeaderSetting" @cancelHeaderChange="cancelHeaderChange" @settingChange="changeCurrentHeaderShow"
                               :current_header_settings="mattersFieldHeaders" :sort-param.sync="sortParam"
                               :page-count="pageCount" :current-page="page" :itemsPerPage="itemsPerPage" @changePerPage="changeItemsPerPage($event)" @currentPage="changeCurrentPage" @sortParam="searchMatterList" :search-by-category="searchByCategory"
                               ref="matterListSearchTable" @updateMatterList="updateMatterList" :silent-load="silentLoad"/>
    </v-card>
    <!-- 検索ボタン -->
    <v-card-actions searchConditionsModalClose v-if="searchConditionsModal">
      <v-row align="center" justify="center" style="height:70px;">
        <v-dialog v-model="searchConditionsModal" persistent max-width="800">
        <PopupSearchConditionsMethodTwo
          ref="searchConditionsModal"
          @modal-close="searchConditionsModalClose"
          @products-selected-method-two-changed="productsSelectedMethodTwoChanged($event)"
          :arrayProductSelected="arrayProductSelected"
        ></PopupSearchConditionsMethodTwo>
      </v-dialog>
      </v-row>
    </v-card-actions>
    <!-- ローディング中にスピナーを表示させる -->
    <v-overlay :value="loadingCount > 0 && !silentLoad">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import constants from '@/components/helpers/const';
import AllMatterSearchTable from "@/components/parts/AllMatterSearchTable";
import PopupSearchConditionsMethodTwo from '@/components/parts/PopupSearchConditionsMethodTwo.vue'
import getMatterListService from '@/services/getMatterListService.js';
import moment from 'moment';
import subtypeServices from "@/services/subtypeServices";
import aggregateTypesServices from "@/services/aggregateTypesServices";
import productClassificationService from "@/services/productClassificationService";
import getProductService from "@/services/getProductService";
import userSettingService from "@/services/userSettingService";
import {category,customerTypeFlg,gender,ageGroup,inquiryType,timeStampFormat} from "@/components/helpers/utils";
import htmlToFormattedText  from 'html-to-formatted-text';

export default {
  components: {
    AllMatterSearchTable,
    PopupSearchConditionsMethodTwo,
  },
  // 画面表示時に行う処理
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.storeHeaderTitleUpdate();
      next();
    });
  },
  data() {
    return {
      // 表示期間_開始日
      periodStartDate: '',
      periodStartDateFlg: false,
      // 表示期間_終了日
      periodEndDate: '',
      periodEndDateFlg: false,
      searchByCategory:false,


      // テーブルに渡す案件リスト
      matterList: [],
      pageCount: null,
      page: 1,
      itemsPerPage: 10,
      sortParam:{
        sortBy: 'inquiryDate',
        sortDesc: [true]
      },
      // loading表示用
      loadingCount: 0,

      refreshMatterList: null,
      silentLoad: false,
      noDateSpecified: false,
      searchCondition: {
        periodStartDate: '',
        periodEndDate: '',
        product_id: [],
        subtype_id: null,
        aggregateType_id: null,
        isExport: false,
        categoryFlg: null,
        countEachCategory: true,
      },

      // subtype
      subTypeList : [],
      subtype_id: null,
      // Aggregate Types
      aggregateTypesList: [],
      aggregateType_id: null,

      // serverFlag
      serverList: [
        {
          label: constants.serverLaBel.all,
          value: constants.serverFlag.all,
        },
        {
          label: constants.serverLaBel.webcs,
          value: constants.serverFlag.webcs,
        },
        {
          label: constants.serverLaBel.oshop,
          value: constants.serverFlag.oshop,
        },
        {
          label: constants.serverLaBel.sid,
          value: constants.serverFlag.sid,
        },
        {
          label: constants.serverLaBel.mo,
          value: constants.serverFlag.mo,
        },
      ],
      server_id: constants.serverFlag.all,

      //classification
      classification_l_code: null,
      classification_l: [],

      classification_m_code: null,
      classification_m: [],

      classification_s_code: null,
      classification_s: [],

      // product_data
      product_choose: [],
      product_list:[],
      product_list_code:[],
      product_data: [],
      product_data_name: '',

      product_item_search: '',
      product_data_cpy: [],

      //matters count
      matters_count: {},

      //todo download CSV
      prepareDownloading: false,
      fileName: '',
      fileHeaders: [],

      currentMatterHeaderShow: [],
      mattersFieldHeaders: [],
      matterHeaders: [],

      categoryFlg:'',

      isMounted:false,
      searchConditionsModal: false,
      arrayProductSelected: [],
      isDefectRequestSearch: '',
      defectFlg: null,
      requestFlg: null,
    }
  },
  watch: {
    page(){
      this.savePageCookie()
    },
    classification_l_code(){
      this.product_data = [] // reset old product data
      if (this.classification_l_code){
        let selected_classification_l = this.classification_l.find(item => item.classification_code === this.classification_l_code);
        if (!selected_classification_l) return;
        this.classification_m_code = null; // reset classification_m\
        this.classification_m = selected_classification_l.sub_classifications;
        //search all product by classification_l
        getProductService.productAllMatterSearch(this.classification_l_code).then(response => {
          this.product_list = response.data.data;
          this.product_data = this.product_list;
          this.product_data_cpy = this.product_data;
        });
      } else {
        this.classification_m = []
        this.classification_m_code = null
        this.product_data = []
        this.product_data_cpy = this.product_data;
      }
    },
    classification_m_code() {
      this.product_data = [] // reset old product data

      if (this.classification_m_code) {
        let selected_classification_m = this.classification_m.find(item => item.classification_code === this.classification_m_code);
        if (!selected_classification_m) return;
        this.classification_s_code = null
        this.classification_s = selected_classification_m.sub_classifications;

        //search all product by classification_m
        this.product_data = this.product_list.filter(item =>
          item.unified_classification_l === this.classification_l_code
          && item.unified_classification_m === this.classification_m_code)
        this.product_data_cpy = this.product_data;
      } else {
        this.classification_s = []
        this.classification_s_code = null
        this.product_data = this.product_list
        this.product_data_cpy = this.product_data;
      }
    },
    classification_s_code() {
      this.product_data = [] // reset old product data

      if(this.classification_s_code){
        //search all product by classification_m
        this.product_data = this.product_list.filter(item =>
          item.unified_classification_l === this.classification_l_code
          && item.unified_classification_m === this.classification_m_code
          && item.unified_classification_s === this.classification_s_code
        )
        this.product_data_cpy = this.product_data;
      } else {
        this.classification_s_code = null
        this.product_data = this.product_list.filter(item =>
          item.unified_classification_l === this.classification_l_code
          && item.unified_classification_m === this.classification_m_code
        )
        this.product_data_cpy = this.product_data;
      }
    },
    product_choose(val) {
      this.arrayProductSelected = val
      this.product_data_name = ''
      if(val.length) {
        this.arrayProductSelected.map(
          product => this.product_data_name += product.product_name + ';\n'
        )
      }
    }
  },
  computed:{
    allProduct_choose(){
      return this.product_data.every(item => this.product_choose.includes(item));
    },

    one_product_choose(){
      return this.product_choose.length > 0 && !this.allProduct_choose
    },

    icon(){
      if(this.allProduct_choose) return 'mdi-checkbox-marked';
      if(this.one_product_choose) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    filteredSubTypeList() {
      return this.subTypeList.filter(subType => {
        const server_subtype = subType.server_subtype ? [...subType.server_subtype].map(Number).filter(num => !isNaN(num)) : [];
        return this.server_id == null || server_subtype.includes(this.server_id)
      });
    },
    filteredAggregateTypesList() {
      return this.aggregateTypesList.filter(item => {
        const server_aggregateTypes = item.server_type ? [...item.server_type].map(Number).filter(num => !isNaN(num)) : [];
        return this.server_id == null || server_aggregateTypes.includes(this.server_id)
      });
    },
  },

  methods: {
    // 検索条件モーダルを開く
    searchConditionsModalOpen() {
      this.searchConditionsModal = true
    },
    searchConditionsModalClose() {
      this.searchConditionsModal = false
    },
    productsSelectedMethodTwoChanged($event) {
      this.product_choose = $event
    },
    saveDataChoose(){
      getProductService.productAllMatterSearch(this.classification_l_code).then(response => {
        this.product_list = response.data.data;
        if (this.classification_l_code && !this.classification_m_code && !this.classification_s_code){
          this.product_data = this.product_list;
          this.product_data_cpy = this.product_data;
        }else if (this.classification_m_code && !this.classification_s_code){
          this.product_data = this.product_list.filter(item =>
            item.unified_classification_l === this.classification_l_code
            && item.unified_classification_m === this.classification_m_code
           )
          this.product_data_cpy = this.product_data;
        }else if (this.classification_s_code){
          this.product_data = this.product_list.filter(item =>
            item.unified_classification_l === this.classification_l_code
            && item.unified_classification_m === this.classification_m_code
            && item.unified_classification_s === this.classification_s_code
          )
          this.product_data_cpy = this.product_data;
        }
      });
    },
    // VuexのstateのheaderTitleを更新
    storeHeaderTitleUpdate() {
      this.$store.dispatch('headerTitleChangeAction', 4);
    },

    // 状態をエンターキーで選択(v-chip)した場合の処理
    statusFlgsUpdate(statusFlg) {
      if (this.selectedStatusFlgs.includes(statusFlg)) {
        this.selectedStatusFlgs = this.selectedStatusFlgs.filter(flg => flg !== statusFlg);
      } else {
        this.selectedStatusFlgs.push(statusFlg);
      }
    },

    // 表示期間の範囲を日付で取得
    getDateRange(periodStartDate, periodEndDate) {
      const startDate = new Date(periodStartDate);
      const endDate = new Date(periodEndDate);
      const dateRange = (endDate - startDate) / 86400000;
      return dateRange;
    },

    // 終了日の初期値（現在の日付）
    initialEndDate() {
      //現在の日付を取得
      let date = new Date();
      const year = date.getFullYear();
      const month = ("00" + (date.getMonth() + 1)).slice(-2);
      const day = ("00" + date.getDate()).slice(-2);
      const currentDate = year + '-' + month + '-' + day;
      this.periodEndDate = currentDate;
    },
    // 開始日の初期値（１週間前の日付）
    initialStartDate() {
      const dash2mttrmode = this.$store.getters.getDash2mattelst
      let date = new Date();
      let day
      // Dashboardのボタンクリックで来た時(当月)
      if (-1 != dash2mttrmode) {
        day = '01'
      } else {
        date.setDate(date.getDate() - 29);
        day = ("00" + date.getDate()).slice(-2);
      }
      let year = date.getFullYear();
      let month = ("00" + (date.getMonth() + 1)).slice(-2);
      let newDate = year + '-' + month + '-' + day;
      this.periodStartDate = newDate;
    },

    // バックエンドから取得した日付データから時分秒を切り捨てる(「yyyy-mm-dd 00:00:00」→「yyyy/mm/dd」)
    dateFormatChange(date) {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      const month = ("00" + (newDate.getMonth() + 1)).slice(-2);
      const day = ("00" + newDate.getDate()).slice(-2);

      return year + '/' + month + '/' + day
    },

    timeStampFormat(date) {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      const month = ("00" + (newDate.getMonth() + 1)).slice(-2);
      const day = ("00" + newDate.getDate()).slice(-2);
      const hour = ("00" + newDate.getHours()).slice(-2);
      const minute = ("00" + newDate.getMinutes()).slice(-2);
      const second = ("00" + newDate.getSeconds()).slice(-2);

      return year + '/' + month + '/' + day + " " + hour + ":" + minute + ':' + second;
    },
    // 検索処理
    searchMatterList: async function () {
      this.loadingCount++;
      try {
        const response = await getMatterListService.getMatterList(
          this.searchCondition,
          this.page,
          this.itemsPerPage,
          Array.isArray(this.sortParam.sortBy)?this.sortParam.sortBy[0]:this.sortParam.sortBy,
          (!this.sortParam.sortDesc || !this.sortParam.sortDesc[0]) ? 'asc' : 'desc',
        );
        // 取得したデータを格納
        this.matterList = response.data.data.data;
        this.matterList.forEach(item => {
          item.gender = gender(item.gender);
          item.age_group = ageGroup(item.age_group);
          item.inquiryType = inquiryType(item.inquiryType);
          item.category = category(item.category);
          item.customer_type_flg = customerTypeFlg(item.customer_type_flg);
          item.createdAt = timeStampFormat(item.createdAt);
        });
        this.pageCount = response.data.data.last_page;
        this.page = response.data.data.current_page;
        this.matters_count = response.data.matters[0];
        for (let i = 0; i < this.matterList.length; i++) {
          // 回答期限の時分秒を切り捨てる
          let matter = this.matterList[i];
          matter.responseLimit = this.dateFormatChange(matter.responseLimit);
          //convert html to text.
          if(!matter.content_type) {
            matter.content = htmlToFormattedText(matter.content);
            matter.content = matter.content.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
          }
        }
      } catch (e) {
        console.log(e);
      }
      this.loadingCount--;
    },
    getCookieExpiredDate(){
      const expiresDate = moment().utcOffset(0).add(1, 'days').set({hour: 0, minute: 0, second: 0, millisecond: 0});
      expiresDate.toISOString();
      expiresDate.format();
      return expiresDate.toDate();
    },

    async matterSearchByCategory(ctgory,searchByCategory = false){
      this.categoryFlg = ctgory;
      const expiresDate = this.getCookieExpiredDate();
      this.$cookies.set("all_matter_search_category_flg",ctgory,  expiresDate);
      this.loadingCount++;
      this.searchCondition.categoryFlg = ctgory;
      this.searchByCategory = searchByCategory;

      this.isDefectRequestSearch = '';
      this.defectFlg = null;
      this.requestFlg = null;
      this.searchCondition.defectFlg = null;
      this.searchCondition.requestFlg = null;
      this.$cookies.remove('all_matter_search_defect_request_flg');

      if(this.searchByCategory){
        this.page = 1;
      }
      if(!ctgory){
        await function (){
          this.searchByCategory = true;
        }
        this.searchByCategory = false;
      }
      try{
        const res = await getMatterListService.getMatterList(
          this.searchCondition,
          this.page,
          this.itemsPerPage,
          Array.isArray(this.sortParam.sortBy)?this.sortParam.sortBy[0]:this.sortParam.sortBy,
          (!this.sortParam.sortDesc || !this.sortParam.sortDesc[0]) ? 'asc' : 'desc',
        )
        // 取得したデータを格納
        this.matterList = res.data.data.data;
        this.matterList.forEach(item => {
          item.gender = gender(item.gender);
          item.age_group = ageGroup(item.age_group);
          item.inquiryType = inquiryType(item.inquiryType);
          item.category = category(item.category);
          item.customer_type_flg = customerTypeFlg(item.customer_type_flg);
        });
        this.pageCount = res.data.data.last_page;
        this.page = res.data.data.current_page;

        this.matters_count = res.data.matters[0];
      } catch (e) {
        console.log(e);
      }
      this.loadingCount--;
    },

    async matterSearchByDefectRequest(searchBy, isSearch = false){
      this.isDefectRequestSearch = searchBy;
      const expiresDate = this.getCookieExpiredDate();
      this.$cookies.set("all_matter_search_defect_request_flg", searchBy, expiresDate);

      this.searchCondition.categoryFlg = null;
      this.categoryFlg = '';
      this.$cookies.remove('all_matter_search_category_flg');

      if(searchBy == 'defect'){
        this.searchCondition.defectFlg = 1;
        this.searchCondition.requestFlg = null;
      }

      if(searchBy == 'request'){
        this.searchCondition.requestFlg = 1;
        this.searchCondition.defectFlg = null;
      }

      this.loadingCount++;
      this.searchByCategory = isSearch;
      if(this.searchByCategory){
        this.page = 1;
      }

      await this.searchMatterList();
      this.loadingCount--;
    },

    async changeCurrentPage(page){
      this.page = page;
      if (this.isMounted){
        if(!this.searchCondition.categoryFlg){
          await this.searchMatterList();
        } else {
          await this.matterSearchByCategory(this.searchCondition.categoryFlg);
        }
      }
    },

    setSearchCondition() {
      this.searchCondition = {
        periodStartDate: this.noDateSpecified ? null : this.periodStartDate.split('-').join(''),
        periodEndDate: this.noDateSpecified ? null : this.periodEndDate.split('-').join(''),
        subtype_id: this.subtype_id,
        aggregateType_id: this.aggregateType_id,
        product_id: this.searchCondition.product_id = this.product_choose.map(item => item.id).length === 0 ? this.product_list_code : this.product_choose.map(item => item.id),
        countEachCategory: true,
        serverFlag: this.server_id,
        defectFlg: this.defectFlg,
        requestFlg: this.requestFlg,
      };
    },
    saveSearchCondition() {
      const expiresDate = this.getCookieExpiredDate();
      this.$cookies.set("all_matter_search_start_date", this.periodStartDate, expiresDate);
      this.$cookies.set("all_matter_search_end_date", this.periodEndDate, expiresDate);
      this.$cookies.set("all_matter_search_classification_l_code", JSON.stringify(this.classification_l_code), expiresDate);
      this.$cookies.set("all_matter_search_classification_m_code", JSON.stringify(this.classification_m_code), expiresDate);
      this.$cookies.set("all_matter_search_classification_s_code", JSON.stringify(this.classification_s_code), expiresDate);
      this.product_list_code = [];
      this.product_choose.map(item => {
          this.product_list_code.push(item.id);
      })
      this.$cookies.set("all_matter_search_product_list_code", JSON.stringify(this.product_list_code), expiresDate);
      this.$cookies.set("all_matter_search_subtype_id", this.subtype_id, expiresDate);
      this.$cookies.set("all_matter_search_aggregate_type_id", this.aggregateType_id, expiresDate);
      this.$cookies.set("all_matter_search_no_date_specified",this.noDateSpecified,  expiresDate);
      this.$cookies.set("all_matter_search_server_flag", this.server_id, expiresDate);
      this.$cookies.remove('all_matter_search_category_flg');
    },
    savePageCookie() {
      const expiresDate = this.getCookieExpiredDate();
      this.$cookies.set("all_matter_search_page", this.page, expiresDate);
      this.$cookies.set("all_matter_search_per_page", this.itemsPerPage, expiresDate);
    },
    async onSearchMatterList() {
      this.page = 1;
      this.$refs.matterListSearchTable.page = 1;
      this.categoryFlg = '';
      this.defectFlg = null;
      this.requestFlg = null;
      this.isDefectRequestSearch = '';
      this.setSearchCondition();
      this.saveSearchCondition();
      await this.searchMatterList();
    },
    async searchMatterListOutside() {
      this.email = null;
      this.address = null;
      this.matterNumber = null;
      await this.onSearchMatterList();
    },
    beforeSearchMatterList() {
      if (this.silentLoad) {
        this.activateErrorNotification({message: '取得処理が終わってからやり直してください'})
      } else this.searchMatterListOutside();
    },
    async updateMatterList() {
      await this.searchMatterList();
    },
    async resetDataSearch() {
      this.initialEndDate();
      this.initialStartDate();
      this.classification_l_code = null;
      this.classification_m_code = null;
      this.classification_s_code = null;
      this.product_data = [];
      this.product_choose = [];
      this.product_list = [];
      this.product_list_code = [];
      this.subtype_id = null;
      this.aggregateType_id = null;
      this.noDateSpecified= false;
      this.searchCondition.categoryFlg=null;
      this.categoryFlg='';
      this.defectFlg = null;
      this.requestFlg = null;
      this.isDefectRequestSearch = '';
      this.server_id=constants.serverFlag.all;
      let cookieParams = ['all_matter_search_no_date_specified','all_matter_search_server_flag','all_matter_search_subtype_id','all_matter_search_aggregate_type_id','all_matter_search_end_date',
        'all_matter_search_classification_s_code','all_matter_search_classification_m_code','all_matter_search_classification_l_code',
        'all_matter_search_start_date','all_matter_search_product_list_code','all_matter_search_category_flg', 'all_matter_search_page','all_matter_search_per_page','all_matter_search_defect_request_flg',];
      cookieParams.forEach(item =>
        this.$cookies.remove(item),
      )
      this.setSearchCondition();
      await this.searchMatterList();
    },

    itemToggle(){
      this.$nextTick(()=>{
        if (this.allProduct_choose){
          this.product_choose = this.product_choose.filter((item)=> !this.product_data.includes(item));
        } else{
          let allChoose = this.product_data;
          allChoose.forEach(item => this.product_choose.push(item));
          this.product_choose = this.product_choose.filter((thing, index, self) =>
              index === self.findIndex((t) => (
                t.id === thing.id
              ))
          )
        }
      })
    },
    //

    async getUserSettings(){
      this.loadingCount++;
      await userSettingService.getMyUserSettings().then(resp => {
        let settings = resp.data.data;
        let isGuest = this.$store.state.auth.user.isGuest;
        if (isGuest){
          this.mattersFields = constants.guestMatterFields;
          this.fileHeaders = constants.guestMatterFileHeaders;
        }else if ((!isGuest && !settings) ||
          (!isGuest && !settings.field_display)){
          this.mattersFields = constants.defaultMatterFields;
          this.fileHeaders = constants.defaultMatterFileHeaders;
        } else {
          this.mattersFields = settings.field_display.split(';').filter(item => item !== '');
          this.fileHeaders = settings.header_display.split(';').filter(item => item !== '');
        }

        if (isGuest){
          this.matterHeaders = constants.guestMatterHeaders;
          this.currentMatterHeaderShow = constants.guestMatterHeaderSettings;
          this.mattersFieldHeaders = this.currentMatterHeaderShow;
        }else if((!isGuest && !settings) || (!isGuest && !settings.matter_fields)) {
          this.matterHeaders = constants.defaultMatterHeaders;

          this.currentMatterHeaderShow = constants.defaultMatterHeaderSettings;
          this.mattersFieldHeaders = this.currentMatterHeaderShow;
        } else {
          this.currentMatterHeaderShow = [];
          let matterHeaderShow = settings.matter_fields.split(';').filter(item => item !== '');
          let matterFieldShow = settings.matter_headers.split(';').filter(item => item !== '');
          let current_matter_headers = [];

          matterHeaderShow.forEach((value, index) => {
            this.currentMatterHeaderShow.push(Object.assign({field: value, display_field_name: matterFieldShow[index]}));
            current_matter_headers.push(Object.assign({
              text: matterFieldShow[index],
              value: value,
              align: 'start',
              width: 130,
            }))
          })
          this.matterHeaders = current_matter_headers;
          this.mattersFieldHeaders = this.currentMatterHeaderShow;
        }
      })
      this.loadingCount--;
    },

    async updateHeaderSetting(){
      await this.getUserSettings();
    },

    changeCurrentHeaderShow(val){
      this.mattersFieldHeaders = val
    },

    cancelHeaderChange(){
      this.mattersFieldHeaders = this.currentMatterHeaderShow;
    },

    searchProduct(){
      if(!this.product_item_search){
        this.product_data = this.product_data_cpy;
      }
      else{
        this.product_data = this.product_data_cpy.filter((product) => {
          return product.product_name.toLowerCase().indexOf(this.product_item_search.toLowerCase()) > -1;
        });
      }
    },

    //todo download csv file
    closeDownloadCSV(){
      this.prepareDownloading = false;
      this.fileName = '';
    },
    async confirmDownload(){
      this.prepareDownloading = false;
      const fileName = this.fileName.replaceAll(' ','')+'.csv';
      this.loadingCount++;
      await getMatterListService.getMattersForCSV(
        this.searchCondition,
        fileName,
        this.fileHeaders,
        this.mattersFields,
        Array.isArray(this.sortParam.sortBy) ? this.sortParam.sortBy[0] : this.sortParam.sortBy,
        (!this.sortParam.sortDesc || !this.sortParam.sortDesc[0]) ? 'asc' : 'desc',)
      this.loadingCount--;
      this.fileName = '';
    },
    async changeItemsPerPage(event){
      this.itemsPerPage = event;
      if(this.page !== 1){
        this.page = 1;
      }else {
        await this.searchMatterList();
      }
      await this.savePageCookie();
    },
    handleServeFlagChange() {
      let subTypeList = this.filteredSubTypeList;
      let aggregateTypesList = this.filteredAggregateTypesList;
      if (subTypeList.length === 0 || !subTypeList.some(subType => subType.id === this.subtype_id)) {
        this.subtype_id = null;
      }
      if (aggregateTypesList.length === 0 || !aggregateTypesList.some(itemType => itemType.id === this.aggregateType_id)) {
        this.aggregateType_id = null;
      }
    },
  },
  async mounted() {
    // 開始日と終了日の初期値設定
    if(this.$cookies.get("all_matter_search_start_date")){
      this.periodStartDate = this.$cookies.get("all_matter_search_start_date");
    }else{
      this.initialStartDate();
    }
    if(this.$cookies.get("all_matter_search_end_date")){
      this.periodEndDate = this.$cookies.get("all_matter_search_end_date");
    }else{
      this.initialEndDate();
    }
    if(this.$cookies.get("all_matter_search_product_list_code") !== 'null' && this.$cookies.get("all_matter_search_product_list_code")){
      if (Array.isArray(this.$cookies.get("all_matter_search_product_list_code"))){
        this.product_list_code = this.$cookies.get("all_matter_search_product_list_code");
      }else{
        this.product_list_code = JSON.parse(this.$cookies.get("all_matter_search_product_list_code"));
      }
    }
    if(this.product_list_code.length) {
      let productsCheckedHistory = []
      getProductService.getProducts(this.product_list_code).then(response => {
        let dataResponse = response.data.data
        if(dataResponse.length) {
          for (let i = 0; i < this.product_list_code.length; i++) {
            let productHistory = dataResponse.filter((product)=> this.product_list_code[i] == product.id)
            if(productHistory[0]) {
              productsCheckedHistory.push(productHistory[0]);
            }
          }
          this.product_choose = productsCheckedHistory
        }
      });
    }
    if(this.$cookies.get("all_matter_search_classification_m_code") !== 'null' && this.$cookies.get("all_matter_search_classification_m_code")){
      this.classification_m_code = JSON.parse(this.$cookies.get("all_matter_search_classification_m_code"));
    }
    if(this.$cookies.get("all_matter_search_classification_l_code") !== 'null' && this.$cookies.get("all_matter_search_classification_l_code")){
      this.classification_l_code = JSON.parse(this.$cookies.get("all_matter_search_classification_l_code"));
    }
    if(this.$cookies.get("all_matter_search_classification_s_code") !== 'null' && this.$cookies.get("all_matter_search_classification_s_code")){
      this.classification_s_code = JSON.parse(this.$cookies.get("all_matter_search_classification_s_code"));
    }
    if(this.$cookies.get("all_matter_search_subtype_id") !== 'null' && this.$cookies.get("all_matter_search_subtype_id")){
      this.subtype_id = Number(this.$cookies.get("all_matter_search_subtype_id"));
    }
    if(this.$cookies.get("all_matter_search_aggregate_type_id") !== 'null' && this.$cookies.get("all_matter_search_aggregate_type_id")){
      this.aggregateType_id = Number(this.$cookies.get("all_matter_search_aggregate_type_id"));
    }
    if(this.$cookies.get("all_matter_search_defect_request_flg") !== 'null' && this.$cookies.get("all_matter_search_defect_request_flg")){
      this.isDefectRequestSearch = this.$cookies.get("all_matter_search_defect_request_flg");
      this.defectFlg = this.isDefectRequestSearch == 'defect' ? 1 : null;
      this.requestFlg = this.isDefectRequestSearch == 'request' ? 1 : null;
    }
    if(this.$cookies.get("all_matter_search_no_date_specified") === 'true' && this.$cookies.get("all_matter_search_no_date_specified")){
      this.noDateSpecified = Boolean(this.$cookies.get("all_matter_search_no_date_specified"));
    }
    if(this.$cookies.get("all_matter_search_category_flg") !== '' && this.$cookies.get("all_matter_search_category_flg")){
      if (this.$cookies.get("all_matter_search_category_flg") === 'null'){
        this.categoryFlg = null;
      }else {
        this.categoryFlg = Number(this.$cookies.get("all_matter_search_category_flg"));
      }
    }
    if(this.$cookies.get("all_matter_search_server_flag") !== 'null' && this.$cookies.get("all_matter_search_server_flag")){
      this.server_id = Number(this.$cookies.get("all_matter_search_server_flag"));
    }
    if(this.$cookies.get("all_matter_search_per_page") !== 'null' && this.$cookies.get("all_matter_search_per_page")){
      this.itemsPerPage = Number(this.$cookies.get("all_matter_search_per_page"));
    }
    this.setSearchCondition();
    if (this.categoryFlg === '' || !this.categoryFlg){
      let pageCookies = this.$cookies.get("all_matter_search_page");
      if(pageCookies !== 'null' && Number(pageCookies) > 0 && pageCookies != this.page){
        this.page = Number(pageCookies);
      }
      await this.searchMatterList();
    }else {
      await this.matterSearchByCategory(this.categoryFlg,true);
    }
    this.isMounted = true;
    this.$store.commit('setDash2mattelst', -1)
  },
  created() {
    subtypeServices.getSubtypeList().then(response => {
      this.subTypeList = response.data.data
    })
    aggregateTypesServices.getAggregateTypesList().then(response => {
      this.aggregateTypesList = response.data.data
    })

    productClassificationService.getClassifiCationChild().then(response => {
      this.classification_l = response.data.data;
      if (this.classification_l_code){
        const _item = this.classification_l.find(item => item.classification_code === this.classification_l_code);
        this.classification_m = _item.sub_classifications;
        if (this.classification_m_code){
          let selected_classification_m = this.classification_m.find(item => item.classification_code === this.classification_m_code);
          this.classification_s = selected_classification_m.sub_classifications;
        }
        this.saveDataChoose();
      }
    })

    this.getUserSettings()
  },
  destroyed() {
    clearInterval(this.refreshMatterList);
  }
}
</script>
<style scoped>

.dash {
  flex-direction: row;
  padding: 0 5px;
  margin-left: 0.5rem;
  margin-bottom: 2rem;
}

.dash-item {
  text-align: center;
  margin-right: 15px;
  width: 150px;
}

.fs-1 {
  font-size: 1rem;
  height: 30px;
  line-height: 30px;
  padding-top: 5px;

}

.fs-2 {
  font-size: 1.5rem;
}
.border-color{
  border: 2px solid red;
}
</style>
