<template>
  <v-card class="ma-2 pa-0 mt-5">
    <v-container>
      <v-row class="justify-center">
        <v-col cols="10">
          <v-row align="center" class="pl-7">
            <v-col cols="2">受付窓口</v-col>
            <v-col cols="9" class="ml-4 d-flex">
              <v-checkbox
                v-for="state in serveFlagStateList"
                :key="state.value"
                :label="state.label"
                :value="state.value"
                v-model="serveFlagState"
                hide-details
                class="mr-3 my-0"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="pl-7">
            <v-col cols="2" class="mt-2">
              <p class="mb-0 text-subtitle-1">小区分</p>
            </v-col>
            <v-col class="ml-4"
              cols="4"
            >
              <v-text-field v-model="subTypeName" maxlength="255"
                            outlined
                            dense></v-text-field>
            </v-col>
            <v-col
              slot="header"
              class="btn-group"
              role="group"
              key="footer"
            >
              <v-btn color="primary" :disabled="subTypeName == '' || serveFlagState.length == 0" @click="add">新規</v-btn>
            </v-col>
          </v-row>
          <draggable tag="ul" :list="subTypeList" @end="endDraging" @change="reOder">
            <transition-group>
              <v-col
                class="list-group-item"
                v-for="(subType, idx) in subTypeList"
                :key="subType.id"
              >
                <v-col>
                  <v-icon class="handle">mdi-drag</v-icon>

                  <v-col class="d-inline" cols="3">
                    <span>{{subType.name}}</span>
                  </v-col>
                </v-col>

                 <v-col>
                   <v-btn class="float-end" color="warning" @click="removeAt(idx,subType.id)">削除</v-btn>
                   <v-btn class="float-end mr-2" color="primary" @click="edit(subType.id,subType.name,subType.server_subtype)">編集</v-btn>
                 </v-col>

              </v-col>
            </transition-group>
          </draggable>

        </v-col>

        <rawDisplayer class="col-3" :value="subTypeList" title="List" />
      </v-row>

      <v-row justify="center">
        <v-dialog
          v-model="editDialog"
          persistent
          max-width="900"
        >
          <v-card>
            <v-toolbar color="primary" dark>
              <span class="text-h5 ml-3">小区分マスタ表示</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="editDialog = false">
                <v-icon large>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row align="center" class="mt-4">
                  <v-col cols="2">受付窓口</v-col>
                  <v-col cols="10" class="px-0 d-flex">
                    <v-checkbox
                      v-for="state in serveFlagStateList"
                      :key="state.value"
                      :label="state.label"
                      :value="state.value"
                      v-model="serveFlagStateChange"
                      hide-details
                      class="mr-3 my-0"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row align="center" class="mt-4">
                  <v-col cols="2" class="pt-0">
                    <p class="text-subtitle-1">小区分</p>
                  </v-col>
                  <v-col cols="9" class="px-0">
                    <v-text-field maxlength="255" outlined dense v-model="newNameChange"></v-text-field>
                  </v-col>
                </v-row>
              <v-row justify="center">
                <v-btn :disabled="!canEdit || newNameChange == ''"
                       rounded
                       color="primary"
                       @click="updateSubtype(subtypeEdit.id,newNameChange,serveFlagStateChange)"
                       x-large class="mb-3"
                >保存</v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <v-snackbar v-model="noti.success"
                :timeout="3000"
                absolute
                bottom
                multi-line
                color="success"
                right>
      <strong><v-icon>mdi-check</v-icon>
        {{noti.notiText}}</strong>
    </v-snackbar>
    <v-snackbar v-model="noti.error"
                :timeout="3000"
                absolute
                bottom
                multi-line
                color="red accent-2"
                right>
      <strong><v-icon>mdi-close</v-icon>
        {{noti.notiText}}</strong>
    </v-snackbar>

  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import subtypeServices from "@/services/subtypeServices";

export default {
  components: {
    draggable
  },
  data() {
    return {
      order: 0,
      id: 0,
      subTypeName: '',
      subTypeList: [],
      dragging: false,
      editDialog: false,
      canEdit: false,

      // notify
      noti:{
        error: false,
        success: false,
        notiText: '',
      },

      // edit
      newNameChange: '',
      subtypeEdit: [
        {
          id: null,
          name: '',
        }
      ],
      serveFlagStateList: [
        { value: 0, label: 'お客様相談室' },
        { value: 1, label: 'オフィシャルショップ' },
        { value: 2, label: 'Shachihata ID' },
        { value: 3, label: 'メールオーダー' },
      ],
      serveFlagStateChange: [],
      serveFlagState: [0],
    };
  },
  mounted() {
    subtypeServices.getSubtypeList().then(Response=>{
      this.subTypeList = Response.data.data;
    });
  },
  methods: {
    reOder(){
      const newSubtypeList = [...this.subTypeList].map((item,index)=>{
        const newOrder = index + 1;
        item.hasChanged = item.sortOrder !== newOrder;
        if(item.hasChanged){
          item.sortOrder = newOrder;
        }
        return item;
      });
      this.subTypeList = newSubtypeList;
    },
    async endDraging(){
      let params = this.subTypeList;
      subtypeServices.orderUpdate(params).then(Response=>{
        this.subTypeList = Response.data.data;
      });
    },
    async reloadList(){
      subtypeServices.getSubtypeList().then(Response=>{
        this.subTypeList = Response.data.data;
      })
    },
    async removeAt(idx,subId) {
      subtypeServices.deleteSubtype(subId).then(()=>{
        this.subTypeList.splice(idx, 1);
      })
    },
     async add() {
      if(this.subTypeName != ''){
        this.order++;
          let params = {
          name: this.subTypeName,
          order: this.order,
          server_subtype: this.serveFlagState,
        }
        subtypeServices.createSubtype(params).then(()=>{
          this.reloadList();
          this.subTypeName = '';
          this.serveFlagState = [0];
        })
      }
    },
    edit(subId,subName,serverSubTypeFlg){
      serverSubTypeFlg = serverSubTypeFlg ? [...serverSubTypeFlg].map(Number).filter(num => !isNaN(num)) : [];
      this.subtypeEdit.id = subId;
      this.subtypeEdit.name = subName;
      this.subtypeEdit.server_subtype = serverSubTypeFlg;
      this.serveFlagStateChange = serverSubTypeFlg;
      this.newNameChange = subName;
      this.editDialog = true;
    },
    async updateSubtype(id,name,serverSubType){
      let params = {name: name, server_subtype: serverSubType};
      subtypeServices.editSubtype(id,params).then(()=>{
        this.editDialog = false;
        this.newNameChange = '';
        this.subtypeEdit.name = '';
        this.subtypeEdit.id = null;
        this.serveFlagStateChange = [0];
        this.subtypeEdit.server_subtype = [];
        this.reloadList();
      }).catch(()=>{
        this.editDialog = false;
        this.newNameChange = '';
        this.subtypeEdit.name = '';
        this.subtypeEdit.id = null;
        this.serveFlagStateChange = [0];
        this.subtypeEdit.server_subtype = [];
        this.reloadList();
      });
    },
    isArraysDifferent() {
        const serveFlagStateChange = [...this.serveFlagStateChange].sort();
        const server_subtype = [...this.subtypeEdit.server_subtype].sort();

        if (serveFlagStateChange.length !== server_subtype.length) {
          return false;
        }
        return serveFlagStateChange.every((value, index) => value === server_subtype[index]);
    },
  },

  watch:{
    subTypeList(){
      if(this.subTypeList.length == 0){
        this.order = 0;
        this.id = 0;
      } else {
        this.order = Math.max.apply(Math, this.subTypeList.map(function (subTypeList){
          return subTypeList.order;
        }));

        this.id = Math.max.apply(Math, this.subTypeList.map(function (subTypeList){
          return subTypeList.id;
        }));
      }
    },
    newNameChange(){
      if(this.serveFlagStateChange.length == 0
        || this.newNameChange == null
        || this.newNameChange == this.subtypeEdit.name
      ){
        this.canEdit = false;
      } else this.canEdit = true;
    },
    serveFlagStateChange(){
      if(this.serveFlagStateChange.length == 0
        || this.newNameChange == null
        || this.isArraysDifferent()
      ){
        this.canEdit = false;
      } else this.canEdit = true;
    }
  }
};
</script>
<style scoped>

.handle {
  float: left;
}
input {
  display: inline-block;
  width: 50%;
}

.list-group-item{
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0px;
}
.list-group-item > div {
  padding: 6px;
}
.list-group-item:hover{
  background-color: #d29e1921;
}
</style>
