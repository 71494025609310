<template>
    <v-card>
      <v-toolbar color="primary" dark>
        <span class="text-h5 ml-3">メール作成(本文 コピー)</span>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-row class="py-2">
          <v-col cols="12">
            <v-textarea
              v-model="textCopy"
              outlined
              auto-grow
              solo
              flat
              hide-details
              style="padding-top: 15px"
              row-height="80"
              placeholder="内容を入力してください"
              disabled
            ></v-textarea>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn
              rounded
              color="primary"
              class="px-12 mx-5"
              @click="doCopy"
            >本文 コピー
            </v-btn>
            <v-btn
              rounded
              color="warning"
              class="px-10 mx-5"
              @click="$emit('textCopyMailClose')"
            >閉じる
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-dialog v-model="notificationCopyMailSuccess" scrollable max-width="500px">
        <v-card>
          <v-card-title class="text-h5" style="margin: auto">クリップボードにコピーしました。</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded color="primary" large class="px-15" @click="notificationCopyMailSuccess = false">確認</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
</template>
<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import htmlToFormattedText  from 'html-to-formatted-text';

Vue.use(VueClipboard)
export default {
    props: {
      matterStatusData: {
        type: [String, Number, Object]
      },
      matterInquiriesData: {
        type: [Array]
      },
      matterCustomerData: {
        type: Object
      },
      textCopyMail: {
        type: Boolean
      },
    },
    data() {
        return {
          textCopy:'',
          notificationCopyMailSuccess:false,
          genderList: [
            {
              gender: 1,
              genderName: '男性',
            },
            {
              gender: 2,
              genderName: '女性',
            },
            {
              gender: 3,
              genderName: 'その他',
            },
          ],
          ageGroupName: ['若年層', '中年層', '高齢層'],
        }
    },
    watch: {
      textCopyMail:{
        immediate:true,
        handler(val){
          if (val == true){
            let content = '';
            let correspondenceInfo = '';
            this.matterInquiriesData.map(item =>{
              if (item.content){
                if(item.content_type) {
                  content = item.content;
                }else {
                  content = htmlToFormattedText(item.content);
                  content = content.replace(/&lt;/g, "<");
                  content = content.replace(/&gt;/g, ">");
                }
              }
              if (item.correspondenceInfo){
                correspondenceInfo += item.correspondenceInfo + "\n";
              }
            })
            let genderName = '';
            this.genderList.map(item =>{
              if (item.gender == this.matterCustomerData.gender){
                genderName = item.genderName;
              }
            })
            let isAgeGroupName = '';
            if(this.matterCustomerData.age_group){
              isAgeGroupName = this.ageGroupName[this.matterCustomerData.age_group - 1];
            }
            let firstInquiryTime = this.matterStatusData.firstInquiryTime ? this.matterStatusData.firstInquiryTime : '';
            let companyName = this.matterCustomerData.companyName ? this.matterCustomerData.companyName : '';
            let departmentName = this.matterCustomerData.departmentName ? this.matterCustomerData.departmentName : '';
            let lastName = this.matterCustomerData.lastName ? this.matterCustomerData.lastName : '';
            let postalCode = this.matterCustomerData.postalCode ? this.matterCustomerData.postalCode : '';
            let prefectureName = this.matterCustomerData.prefectureName ? this.matterCustomerData.prefectureName : '';
            let address = this.matterCustomerData.address ? this.matterCustomerData.address : '';
            let title = this.matterStatusData.title ? this.matterStatusData.title : '';
            let phoneNumber = this.phoneNumber();
            let email = this.email();
            this.textCopy ='対応開始 : ' + firstInquiryTime + '\n'+
              '会社名 : ' + companyName + '\n'+
              '部署名 : ' + departmentName + '\n'+
              '氏名 : ' + lastName + '\n'+
              '性別 : ' + genderName + '\n'+
              '年齢層 : ' + isAgeGroupName + '\n'+
              '郵便番号 : ' + postalCode + '\n'+
              '住所 (都道府県) : ' + prefectureName + '\n'+
              '住所 (都道府県以下) : ' + address + '\n'+
              '電話番号 : ' + phoneNumber + '\n'+
              'メールアドレス : ' + email + '\n'+
              '件名 : ' + title + '\n'+
              '内容 : ' + content + '\n'+
              '対応内容 : ' + correspondenceInfo
          }
        }
      }
    },
  methods: {
    doCopy: function () {
      this.$emit('textCopyMailClose');
      this.$copyText(this.textCopy).then(function (e) {
        navigator.clipboard.writeText(e.text);
      }, function (e) {
        console.log(e)
      })
      this.notificationCopyMailSuccess = true;
    },
    phoneNumber(){
        let phoneNumber= '';
        if (this.matterCustomerData.phoneNumber1){
          phoneNumber = this.matterCustomerData.phoneNumber1;
        }else if (this.matterCustomerData.phoneNumber2){
          phoneNumber = this.matterCustomerData.phoneNumber2;
        }
        return phoneNumber
    },
    email(){
      let email= '';
      if (this.matterCustomerData.email1){
        email = this.matterCustomerData.email1;
      }else if (this.matterCustomerData.email2){
        email = this.matterCustomerData.email2;
      }
      return email;
    }
  }
}
</script>
<style scoped></style>
