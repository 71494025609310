import http from '@/httpCommon.js';

export default {
  async getDataDashBoard(paramsDashBoard) {
    // await sleep(500);
    return http.post('/get-data-dashboard', {
      params: {
        typeTimeTarget: paramsDashBoard.typeTimeTarget,
        itemTarget: paramsDashBoard.itemTarget,
        monthlyTarget: paramsDashBoard.monthlyTarget,
        startDate: paramsDashBoard.startDate,
        endDate: paramsDashBoard.endDate,
        server_flg: paramsDashBoard.serverFlag
      },
    });
  },
  async getTypeMatterCount(serverFlag) {
    // await sleep(500);
    return http.get( `/get-type-matter-count?server_flg=${serverFlag}`);
  },
};
