<template>
    <!-- <v-container class="grey lighten-3"> -->
    <v-card class="ma-1" :style="{backgroundColor: this.$store.state.backgroundColorCustomer ? this.$store.state.backgroundColorCustomer : this.defaultBackgroundColor}">
        <v-card-title>
            <v-row align="center">
                <v-col cols="auto" class="py-1">
                    <!-- 最小化による表示の切り替え -->
                    <div>
                        <span class="text-h6 ml-3 mr-3">お客様情報</span>
                    </div>
                </v-col>
                <div v-show="0 == matterId">
                    <v-switch
                        class="mr-5"
                        v-model="anonymous"
                        label="匿名"
                        @change="anonymousSwichEvnet"
                    ></v-switch>
                </div>
                <div class="py-1">
                  <v-btn
                    rounded
                    color="success"
                    class="px-15 text-subtitle-1 mr-2"
                    :disabled="readonlyFlg || anonymous"
                    @click="searchConditionsModalOpen"
                  >検索
                  </v-btn>
              </div>
                <v-spacer></v-spacer>
                <!-- お客様特定による表示の切り替え -->
                <div v-show="!customerIdCheck">
                    <p class="mb-0 grey--text text-darken-1 text-h6 mr-12">お客様未特定</p>
                </div>
                <div v-show="customerIdCheck">
                    <p class="mb-0 blue--text text-darken-4 text-h6 font-weight-bold mr-12" v-if="matterCustomerData.attentionFlg == null">お客様特定済み</p>
                    <p class="mb-0 orange--text text-darken-4 text-h6 font-weight-bold mr-12" v-if="matterCustomerData.attentionFlg == 0">お客様特定済み（<img width="20px" src="@/assets/warning.png"  alt="警告">注意人物！）</p>
                    <p class="mb-0 red--text text-darken-4 text-h6 font-weight-bold mr-12" v-if="matterCustomerData.attentionFlg == 1">お客様特定済み（<img width="20px" src="@/assets/warning.png"  alt="警告">要注意人物！<img width="20px" src="@/assets/warning.png"  alt="警告">）</p>
                </div>
                <v-btn
                  rounded
                  class="px-6 mr-6"
                  color="primary"
                  @click="openColorPickerBackgroundModal"
                > 背景設定
                </v-btn>
              <v-dialog v-model="colorPickerBackground"
                        persistent
                        scrollable
                        max-width="350px">
                <ColorPickerBackgroundModal
                  @closeColorPickerBackgroundModal="closeColorPickerBackgroundModal"
                  @updateBackgroundColor="updateBackgroundColor"
                  @resetBackgroundColor="resetBackgroundColor"
                  :backgroundColor="backgroundColor"
                  :defaultBackgroundColor="defaultBackgroundColor"
                />
              </v-dialog>
            </v-row>
        </v-card-title>
        <!-- 最小化状態なら非表示 -->
        <v-divider class="mx-4 mb-3"></v-divider>
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <VueReadMoreSmooth :lines="0" :open="true" :no-button="true">
            <v-row>
              <v-col cols="8">
              <div>
                  <v-card-text class="py-1">
                    <v-form ref="form">
                      <!-- お客様特定済みの場合にのみ表示する -->
                      <v-row align="center" class="pt-1 pb-3">
                        <v-col cols="6">
                          <v-row>
                            <v-col cols="4">
                              <p class="mb-0 text-subtitle-1">お客様ID</p>
                            </v-col>
                            <v-col cols="8">
                              <v-text-field
                                v-model="customerId"
                                solo
                                outlined
                                hide-details
                                dense
                                ref="idCustomerClear"
                                flat
                                readonly
                                background-color="grey lighten-2"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <p class="mb-0 text-subtitle-1">お客様種別</p>
                            </v-col>
                            <v-col cols="8">
                              <v-radio-group
                                v-model="customerTypeFlg"
                                row
                                hide-details
                                class="mt-0 pt-0"
                                tabindex="1"
                                :disabled="readonlyFlg"
                                :background-color="getBackgroundColor(matterCustomerData.customerTypeFlg, customerTypeFlg)"
                                @change="updateCustomerDifferentDataList()"
                              >
                                <v-radio
                                  v-for="customerType in customerTypeList"
                                  :key="customerType.typeFlg"
                                  :label="customerType.typeName"
                                  :value="customerType.typeFlg"
                                  class="mr-3"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col cols="4">
                              <p class="mb-0 text-subtitle-1">名前</p>
                            </v-col>
                            <v-col cols="8" class="py-0">
                              <v-text-field
                                v-model="customerLastName"
                                label="姓名（漢字）"
                                solo
                                flat
                                tabindex="2"
                                outlined
                                hide-details
                                dense
                                :clearable="!readonlyFlg"
                                :readonly="readonlyFlg"
                                :background-color="getBackgroundColor(matterCustomerData.lastName, customerLastName)"
                                @change="updateCustomerDifferentDataList()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <p class="mb-0 text-subtitle-1">よみ</p>
                            </v-col>
                            <v-col cols="8" class="py-0">
                              <v-text-field
                                v-model="customerLastNameKana"
                                label="姓名（ひらがな）"
                                solo
                                flat
                                outlined
                                hide-details
                                dense
                                tabindex="3"
                                :clearable="!readonlyFlg"
                                :readonly="readonlyFlg"
                                :background-color="getBackgroundColor(matterCustomerData.lastNameKana, customerLastNameKana)"
                                @change="updateCustomerDifferentDataList()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <p class="text-subtitle-1 mb-0">メールアドレス１</p>
                            </v-col>
                            <v-col cols="8" class="py-0">
                              <validation-provider
                                v-slot="{ errors }"
                                name="メールアドレス１"
                                rules="email"
                              >
                                <v-text-field
                                  v-model="email1"
                                  placeholder="メールアドレスを入力"
                                  solo
                                  flat
                                  outlined
                                  hide-details="auto"
                                  dense
                                  tabindex="4"
                                  :clearable="!readonlyFlg"
                                  :readonly="readonlyFlg"
                                  :background-color="getBackgroundColor(matterCustomerData.email1, email1)"
                                  @change="updateCustomerDifferentDataList()"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col cols="4">
                              <p class="text-subtitle-1 mb-0">メールアドレス２</p>
                            </v-col>
                            <v-col cols="8" class="py-0">
                              <validation-provider
                                v-slot="{ errors }"
                                name="メールアドレス2"
                                rules="email"
                              >
                                <v-text-field
                                  v-model="email2"
                                  placeholder="メールアドレスを入力"
                                  solo
                                  flat
                                  outlined
                                  hide-details="auto"
                                  dense
                                  tabindex="5"
                                  :clearable="!readonlyFlg"
                                  :readonly="readonlyFlg"
                                  :background-color="getBackgroundColor(matterCustomerData.email2, email2)"
                                  @change="updateCustomerDifferentDataList()"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col cols="4">
                              <p class="text-subtitle-1 mb-0">業種名</p>
                            </v-col>
                            <v-col cols="8" class="py-0">
                              <v-text-field
                                v-model="industryName"
                                placeholder="業種名を入力"
                                solo
                                flat
                                outlined
                                hide-details
                                dense
                                tabindex="6"
                                :clearable="!readonlyFlg"
                                :readonly="readonlyFlg"
                                :background-color="getBackgroundColor(matterCustomerData.industryName, industryName)"
                                @change="updateCustomerDifferentDataList()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <p class="mb-0 text-subtitle-1">部署名</p>
                            </v-col>
                            <v-col cols="8" class="py-0">
                              <v-text-field
                                v-model="departmentName"
                                placeholder="部署名を入力"
                                solo
                                flat
                                outlined
                                hide-details
                                dense
                                tabindex="7"
                                :clearable="!readonlyFlg"
                                :readonly="readonlyFlg"
                                :background-color="getBackgroundColor(matterCustomerData.departmentName, departmentName)"
                                @change="updateCustomerDifferentDataList()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <p class="mb-0 text-subtitle-1">ＦＡＸ番号</p>
                            </v-col>
                            <v-col cols="8" class="py-0">
                              <validation-provider v-slot="{ errors }" rules="faxNumber">
                                <v-text-field
                                  v-model="faxNumber"
                                  placeholder="例：0312345678 (半角･ハイフンなし)"
                                  hint="(半角･ハイフンなし｜例：0312345678)"
                                  solo
                                  flat
                                  outlined
                                  hide-details="auto"
                                  dense
                                  tabindex="8"
                                  :clearable="!readonlyFlg"
                                  :readonly="readonlyFlg"
                                  :background-color="getBackgroundColor(matterCustomerData.faxNumber, faxNumber)"
                                  @change="updateCustomerDifferentDataList()"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" style="margin-top: 35px">
                          <v-row>
                            <v-col cols="4" class="py-0">
                              <p class="mb-0 text-subtitle-1">性別</p>
                            </v-col>
                            <v-col cols="8" class="py-0">
                              <v-radio-group
                                v-model="gender"
                                row
                                hide-details
                                class="mt-0 pt-0"
                                tabindex="10"
                                :disabled="readonlyFlg"
                                :background-color="getBackgroundColor(matterCustomerData.gender, gender)"
                                @change="updateCustomerDifferentDataList()"
                              >
                                <v-radio
                                  v-for="gender in genderList"
                                  :key="gender.gender"
                                  :label="gender.genderName"
                                  :value="gender.gender"
                                  class="mr-3"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col cols="4" class="py-2">
                              <p class="mb-0 text-subtitle-1">年齢層</p>
                            </v-col>
                            <v-col cols="8" class="py-2">
                              <v-radio-group
                                v-model="ageGroup"
                                row
                                hide-details
                                class="mt-0 pt-0"
                                tabindex="10"
                                :disabled="readonlyFlg"
                                :background-color="getBackgroundColor(matterCustomerData.age_group, ageGroup)"
                                @change="updateCustomerDifferentDataList()"
                              >
                                <v-radio
                                  v-for="item in ageGroupList"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                                  class="mr-3"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col cols="4" class="py-0 align-center d-flex">
                              <p class="mb-0 text-subtitle-1">会社名/販売店名</p>
                            </v-col>
                            <v-col cols="8" class="py-0">
                              <v-text-field
                                v-model="companyName"
                                placeholder="株式会社〇〇"
                                solo
                                flat
                                outlined
                                hide-details
                                dense
                                tabindex="11"
                                :clearable="!readonlyFlg"
                                :readonly="readonlyFlg"
                                :background-color="getBackgroundColor(matterCustomerData.companyName, companyName)"
                                @change="updateCustomerDifferentDataList()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" style="padding-bottom: 0">
                              <p class="mb-0 text-subtitle-1" style="padding-top: 10px;">よみ</p>
                            </v-col>
                            <v-col cols="8" style="padding-bottom: 0">
                              <v-text-field
                                placeholder="かぶしきかいしゃ〇〇"
                                v-model="companyNameKana"
                                solo
                                flat
                                outlined
                                hide-details
                                dense
                                tabindex="12"
                                :clearable="!readonlyFlg"
                                :readonly="readonlyFlg"
                                :background-color="getBackgroundColor(matterCustomerData.companyNameKana, companyNameKana)"
                                @change="updateCustomerDifferentDataList()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" style="padding-bottom: 0">
                              <p class="mb-0 text-subtitle-1" style="padding-top: 10px;">電話番号１</p>
                            </v-col>
                            <v-col cols="8" style="padding-bottom: 0">
                              <validation-provider v-slot="{ errors }" rules="phoneNumber">
                                <v-text-field
                                  v-model="phoneNumber1"
                                  placeholder="例：09012345678 (半角･ハイフンなし)"
                                  solo
                                  flat
                                  hint="(半角･ハイフンなし｜例：09012345678)"
                                  outlined
                                  hide-details="auto"
                                  dense
                                  tabindex="13"
                                  :clearable="!readonlyFlg"
                                  :readonly="readonlyFlg"
                                  :background-color="getBackgroundColor(matterCustomerData.phoneNumber1, phoneNumber1)"
                                  @change="updateCustomerDifferentDataList()"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col cols="4" style="padding-bottom: 0">
                              <p class="mb-0 text-subtitle-1" style="padding-top: 10px;">電話番号２</p>
                            </v-col>
                            <v-col cols="8" style="padding-bottom: 0">
                              <validation-provider v-slot="{ errors }" rules="phoneNumber">
                                <v-text-field
                                  v-model="phoneNumber2"
                                  placeholder="例：09012345678 (半角･ハイフンなし)"
                                  hint="(半角･ハイフンなし｜例：09012345678)"
                                  solo
                                  flat
                                  outlined
                                  hide-details="auto"
                                  dense
                                  tabindex="14"
                                  :clearable="!readonlyFlg"
                                  :readonly="readonlyFlg"
                                  :background-color="getBackgroundColor(matterCustomerData.phoneNumber2, phoneNumber2)"
                                  @change="updateCustomerDifferentDataList()"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col cols="4" style="padding-bottom: 0">
                              <p class="mb-0 text-subtitle-1" style="padding-top: 10px;">郵便番号</p>
                            </v-col>
                            <v-col cols="6" style="padding-bottom: 0">
                              <validation-provider
                                v-slot="{ errors }"
                                name="郵便番号"
                                rules="postalCode"
                              >
                                <v-text-field
                                  v-model="postalCode"
                                  placeholder="例：0001234 (半角･ハイフンなし)"
                                  solo
                                  flat
                                  hint="(半角･ハイフンなし｜例：0001234)"
                                  outlined
                                  hide-details="auto"
                                  dense
                                  tabindex="15"
                                  :clearable="!readonlyFlg"
                                  :readonly="readonlyFlg"
                                  :background-color="getBackgroundColor(matterCustomerData.postalCode, postalCode)"
                                  @input="changePostalCode"
                                  @change="updateCustomerDifferentDataList"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col cols="4" style="padding-bottom: 0">
                              <p class="mb-0 text-subtitle-1" style="padding-top: 10px;">都道府県</p>
                            </v-col>
                            <v-col cols="5" style="padding-bottom: 0">
                              <v-select
                                v-model="prefectureName"
                                :items="prefectureNameList"
                                placeholder="選択してください"
                                solo
                                flat
                                outlined
                                hide-details="auto"
                                dense
                                tabindex="16"
                                :clearable="!readonlyFlg"
                                :readonly="readonlyFlg"
                                :background-color="getBackgroundColor(matterCustomerData.prefectureName, prefectureName)"
                                @change="updateCustomerDifferentDataList()"
                              ></v-select>
                            </v-col>
                            <v-col cols="4"  style="padding-bottom: 0">
                              <p class="mb-0 text-subtitle-1" style="padding-top: 10px;">住所</p>
                            </v-col>
                            <v-col cols="8"  style="padding-bottom: 0">
                              <v-text-field
                                v-model="address"
                                placeholder="例：〇〇市△△区〇〇町0-0"
                                solo
                                flat
                                outlined
                                hide-details
                                dense
                                tabindex="17"
                                :clearable="!readonlyFlg"
                                :readonly="readonlyFlg"
                                :background-color="getBackgroundColor(matterCustomerData.address, address)"
                                @change="updateCustomerDifferentDataList()"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <v-row>
                            <v-col cols="6">
                              <v-row>
                                <v-col cols="4">
                                  <p class="mb-0 text-subtitle-1">購入先</p>
                                </v-col>
                                <v-col cols="8" class="py-0">
                                  <v-text-field
                                    v-model="retailerName"
                                    placeholder="購入先を入力"
                                    solo
                                    flat
                                    outlined
                                    hide-details
                                    dense
                                    tabindex="9"
                                    :clearable="!readonlyFlg"
                                    :readonly="readonlyFlg"
                                    :background-color="getBackgroundColor(matterCustomerData.retailerName, retailerName)"
                                    @change="updateCustomerDifferentDataList()"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="6">
                              <v-row class="float-right" style="margin-right: 0px;">
                                <v-btn
                                  rounded
                                  color="primary"
                                  large
                                  v-if="!readonlyFlg"
                                  :disabled="matterCustomerData.attentionFlg == 0 || matterCustomerData.attentionFlg ==1"
                                  @click="attentionCustomerSave(matterId)"
                                  class="px-12 text-subtitle-1"
                                  v-show="customerIdCheck"
                                >注意人物登録</v-btn>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <v-row>
                            <v-col cols="2" style="padding-top: 5px">
                              <p class="mb-0 text-subtitle-1">備考</p>
                            </v-col>
                            <v-col cols="10" style="padding-top: 0">
                              <v-text-field
                                v-model="remarks"
                                placeholder="備考を入力"
                                solo
                                flat
                                outlined
                                hide-details
                                dense
                                tabindex="18"
                                :clearable="!readonlyFlg"
                                :readonly="readonlyFlg"
                                :background-color="getBackgroundColor(matterCustomerData.remarks, remarks)"
                                @change="updateCustomerDifferentDataList()"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" style="padding-top: 12px">
                          <v-row>
                            <v-col cols="4" style="padding-top: 5px">
                              <p class="mb-0 text-subtitle-1">最終更新者</p>
                            </v-col>
                            <v-col cols="8" style="padding-top: 0">
                              <v-text-field
                                v-model="updatedBy"
                                solo
                                flat
                                outlined
                                hide-details
                                dense
                                readonly
                                background-color="grey lighten-2"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" style="padding-top: 12px">
                          <v-row>
                            <v-col cols="4" style="padding-top: 5px">
                              <p class="mb-0 text-subtitle-1">最新更新日</p>
                            </v-col>
                            <v-col cols="8" style="padding-top: 0">
                              <v-text-field
                                v-model="updatedAt"
                                solo
                                flat
                                outlined
                                hide-details
                                dense
                                readonly
                                background-color="grey lighten-2"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                    <!-- </transition> -->
                  </v-card-text>
                <v-card-actions v-show="!historyModalFlg" style="padding: 1px">
                  <v-row align="center" justify="center">
                    <!-- 検索条件モーダル -->
                    <v-dialog v-model="searchConditionsModal" persistent max-width="50%" style="margin-top:70px;">
                      <SearchConditions-component
                        ref="searchConditionsModal"
                        :ndDisplayTelSearch="ndDisplayTelSearch"
                        @modal-close="searchConditionsModalClose"
                        @add-table="customerServiceAddition($event, matterId)"
                        @get-data-detail="getCustomerMasterData($event, matterId)"
                      ></SearchConditions-component>
                    </v-dialog>
                  </v-row>
                </v-card-actions>
                <!-- DB保存時に確認用のモーダルを表示 -->
                <!-- <v-dialog v-model="confirmModalFlg" transition="dialog-top-transition" max-width="800">
                    <v-card>
                        <v-toolbar dense color="primary" dark class="text-h6">【確認】入力した内容でお客様情報を保存しますか？</v-toolbar>
                        <v-card-actions style="height:70px;">
                            <v-row align="center" justify="center">
                                <v-btn rounded color="warning" large class="px-12 mr-15" @click="$emit('modal-close-button-click')">いいえ</v-btn>
                                <v-btn rounded color="primary" large class="px-14 ml-15" @click="$emit('matter-close-button-click')">はい</v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>-->
              </div>
              </v-col>
              <v-col cols="4">
              <div>
                <CustomerInformationMatterListSearchTable
                  ref="CustomerInformationMatterListSearchTable"
                  :matter-list="scrollDataList"
                  :matterCustomerData="matterCustomerData"
                  :customerId = "customerId"
                  :sort-param.sync="sortParam"
                  :page-count="pageCount"
                  :total="total"
                  @curentPage="page = $event"
                  @inquiryContentsModalOpen="(item) =>$emit('customerInquiryContents',item)"
                  @updateMatterList="updateMatterList"/>
              </div>
            </v-col>
            </v-row>
          </VueReadMoreSmooth>
          <v-row>
            <v-col cols="12">
              <v-divider class="mx-4 mt-1" />
              <v-card-actions v-show="!historyModalFlg" style="padding: 20px;">
                <v-row align="center" justify="space-between" style="height:70px; padding: 0 27px;">
                  <div>
                    <v-btn
                      v-if="customerId !== null"
                      rounded
                      color="primary"
                      large
                      class="px-16 text-subtitle-1"
                      :disabled="readonlyFlg || invalid || !customerId"
                      @click="customerSave(matterId,1)"
                    >更新</v-btn>
                  </div>
                  <div>
                    <v-btn
                      v-if="customerId === null"
                      rounded
                      color="primary"
                      large
                      class="px-12 text-subtitle-1"
                      :disabled="readonlyFlg || invalid"
                      @click="customerSave(matterId,2)"
                    >新規登録</v-btn>
                    <v-btn
                      v-if="customerId"
                      rounded
                      color="primary"
                      large
                      class="px-12 text-subtitle-1 ml-15"
                      :disabled="readonlyFlg"
                      @click="customerClone(matterId)"
                    >新規登録</v-btn>
                  </div>
                  <v-btn
                    rounded
                    color="warning"
                    large
                    class="px-12 text-subtitle-1 pr-3 pl-3"
                    :disabled="readonlyFlg"
                    @click="reset"
                  >入力クリア</v-btn>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </ValidationObserver>
        <v-dialog v-model="notificationAttentionsFlg" scrollable max-width="500px">
          <v-card>
            <v-card-title class="text-h5" style="margin: auto">注意人物に登録しました。</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn rounded color="primary" large class="px-15" @click="closeModalNotificationsAttentionFlg">確認</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <!-- ローディング中にスピナーを表示させる -->
        <v-overlay :value="loadingCount > 0">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
    </v-card>
    <!-- </v-container> -->
</template>

<script>
import updateMatterService from '@/services/updateMatterService.js';
import SearchConditions from './CustomerInformationSearchConditionsModal.vue'
import ColorPickerBackgroundModal from './ColorPickerBackgroundModal.vue'
// バリデーション
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
import CustomerInformationMatterListSearchTable from '@/components/parts/CustomerInformationMatterListSearchTable.vue';

// バリデーション日本語化
import ja from 'vee-validate/dist/locale/ja.json';
import postal_code from 'japan-postal-code';
import {getBackgroundColor} from '@/components/helpers/utils';
import getMatterListService from "@/services/getMatterListService";
import userSettingService from "@/services/userSettingService";
import VueReadMoreSmooth from "vue-read-more-smooth";

localize('ja', ja);

// バリデーション
// 編集なし（デフォルトのルール・メッセージを使用
extend('email', email);
// カスタムルールの設定
// 郵便番号
extend('postalCode', {
    // エラーメッセージを設定する
    message: '{_field_}はハイフンなしの【半角数字】7桁で入力してください',
    validate(value) {
        if (value.match(/^[0-9]{7}$/)) {
            return true;
        }
    }
});
// 電話番号
extend('phoneNumber', {
    // エラーメッセージを設定する
    message: '有効な電話番号を入力してください(半角数字･ハイフンなし)',
    // /^0[1-9][0-9]{0,4}-[0-9]{1,5}-[0-9]{1,5}$/
    validate(value) {
        if (value.match(/^0[1-9][0-9]{8,9}$/) || value === '非通知' || value.toLowerCase() === 'unknown') {
            return true;
        }
    }
});
// FAX番号
extend('faxNumber', {
    // エラーメッセージを設定する
    message: '有効なFAX番号を入力してください(半角数字･ハイフンなし)',
    validate(value) {
        if (value.match(/^0[0-9]{9,10}$/)) {
            return true;
        }
    }
});

export default {
    props: {
        // 親コンポーネントよりmatterIdを取得
        matterId: {
            type: Number,
        },
        matterDetailInquiriesData: {
          type: [Array]
        },
        // 案件データを取得
        matterCustomerData: {
            type: [String, Number, Object]
        },
        readonlyFlg: {
            type: Boolean,
        },
        // 保存されていない変更項目リストを取得
        customerDifferentDataList: {
            type: [String, Number, Object, Array]
        },
        // 問い合わせ履歴モーダル
        historyModalFlg: {
            type: Boolean,
        },
        ndDisplayTelSearch: {
          type: String,
        },
    },
    components: {
        'SearchConditions-component': SearchConditions,
        // バリデーション
        ValidationProvider,
        ValidationObserver,
        CustomerInformationMatterListSearchTable,
        ColorPickerBackgroundModal,
        VueReadMoreSmooth,
    },
    data() {
        return {
            total:null,
            page: 1,
            pageCount: null,
            sortParam:{
              sortBy: 'inquiryDate',
              sortDesc: [true]
            },
            matterList: [],
            // お客様ID
            customerId: null,
            // 名前(姓)
            customerLastName: '',
            // よみ(姓)
            customerLastNameKana: '',
            // メールアドレス1
            email1: '',
            // メールアドレス2
            email2: '',
            // 会社名/販売店名
            companyName: '',
            // 会社名/販売店名(よみ)
            companyNameKana: '',
            // 電話番号1
            phoneNumber1: '',
            // 電話番号2
            phoneNumber2: '',
            // お客様種別
            customerTypeList: [
                {
                    typeFlg: 0,
                    typeName: '個人消費者',
                },
                {
                    typeFlg: 1,
                    typeName: '企業消費者',
                },
                {
                    typeFlg: 2,
                    typeName: '販売店',
                },
                {
                  typeFlg: 3,
                  typeName: '支店',
                },
                {
                    typeFlg: 4,
                    typeName: 'その他',
                },
            ],
            // バックエンドからの取得値
            customerTypeFlg: null,

            // 性別
            genderList: [
                {
                    gender: 1,
                    genderName: '男性',
                },
                {
                    gender: 2,
                    genderName: '女性',
                },
                {
                    gender: 3,
                    genderName: 'その他',
                },
            ],
            // バックエンドからの取得値
            gender: null,

            // FAX番号
            faxNumber: '',
            // 業種名
            industryName: '',
            // 部署名
            departmentName: '',
            // 購入先
            retailerName: '',
            // 郵便番号
            postalCode: '',
            // 都道府県
            prefectureName: '',
            prefectureNameList: ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県',
                '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県',
                '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県',
                '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'],
            // 住所
            address: '',
            // 備考
            remarks: '',
            // 最終更新者
            updatedBy: '',
            // 最終更新日
            updatedAt: '',

            // 最小化の表示を切り替えるflg
            minimizeFlg: false,

            // 検索結果モーダルの表示Flg
            searchResultModalFlg: false,
            // ボタンクリック時に表示する確認用モーダル
            // confirmModalFlg: false,
            // 検索条件モーダルflg
            searchConditionsModal: false,
            // 検索結果に一致したお客様情報をバックエンドから取得
            searchResultCustomerList: [],
            // loading時間
            loadingCount: 0,
            // 匿名
            anonymous: false,

            // お客様情報(データ)
            customerData: {
                id: 0,
                lastName: '',
                lastNameKana: '',
                email1: '',
                email2: '',
                phoneNumber1: '',
                phoneNumber2: '',
                customerTypeFlg: '',
                attentionFlg: '',
                companyName: '',
                companyNameKana: '',
                gender: '',
                age_group: '',
                faxNumber: '',
                industryName: '',
                departmentName: '',
                postalCode: '',
                prefectureName: '',
                address: '',
                retailerName: '',
                remarks: ''
            },
            // DBの値と現在の値が違う項目のリスト(状態パネルの「問い合わせを閉じる」ボタンを押した時に表示するモーダルに使用する)
            differentDataList: [],
            mountCompletion: false,
            // scroll loading
            scrollDataList: [],
            scrollPage:null,
            notificationAttentionsFlg:false,
            colorPickerBackground:false,
            backgroundColor: this.$store.state.backgroundColorCustomer,
            defaultBackgroundColor: '#BAD0C6',
            ageGroupList: [
              { value: 1,  label: '若年層'},
              { value: 2,  label: '中年層'},
              { value: 3,  label: '高齢層'},
            ],
            ageGroup: null,
        }
    },
    watch:{
      $data: {
        handler(val){
          this.$emit('changePhoneNumber1',val.phoneNumber1)
          if (this.mountCompletion){
            this.saveState(val)
          }
        },
        deep: true
      },
      $route() {
        this.mountCompletion = true;
        this.restoreState();
      },
      matterId: function(){
        this.mountCompletion = false;
      },
    },

    methods: {
        getBackgroundColor,
        async updateMatterList(page){
          this.page = page
          await this.searchMatterList(this.customerData,page);
        },
      // バックエンドから取得した日付データから時分秒を切り捨てる(「yyyy-mm-dd 00:00:00」→「yyyy/mm/dd」)
        dateFormatChange(date) {
          const newDate = new Date(date);
          const year = newDate.getFullYear();
          const month = ("00" + (newDate.getMonth() + 1)).slice(-2);
          const day = ("00" + newDate.getDate()).slice(-2);

          return year + '/' + month + '/' + day
        },
        // 検索処理
        searchMatterList: async function (data,page) {
          this.$refs.CustomerInformationMatterListSearchTable.loadingCount++;
            try {
              if (data.phoneNumber1 || data.phoneNumber2){
                const response = await getMatterListService.getPhoneCustomerMatterList(
                  this.customerPhoneNum1 = data.phoneNumber1,
                  this.customerPhoneNum2 = data.phoneNumber2,
                  this.phoneNumberFlg = 1,
                  this.page = page,
                  Array.isArray(this.sortParam.sortBy)?this.sortParam.sortBy[0]:this.sortParam.sortBy,
                  (!this.sortParam.sortDesc || !this.sortParam.sortDesc[0]) ? 'asc' : 'desc'
                );
                // 取得したデータを格納
                this.matterList = response.data.data.data;
                this.pageCount = response.data.data.last_page;
                this.total = response.data.data.total;
              }else if (!data.phoneNumber1 && !data.phoneNumber2 && data.email1||
                !data.phoneNumber1 && !data.phoneNumber2 && data.email2){
                const response = await getMatterListService.getEmailCustomerMatterList(
                  this.email1 = data.email1,
                  this.email2 = data.email2,
                  this.emailFlg = 1,
                  this.page = page,
                  Array.isArray(this.sortParam.sortBy)?this.sortParam.sortBy[0]:this.sortParam.sortBy,
                  (!this.sortParam.sortDesc || !this.sortParam.sortDesc[0]) ? 'asc' : 'desc'
                );
                // 取得したデータを格納
                this.matterList = response.data.data.data;
                this.pageCount = response.data.data.last_page;
                this.total = response.data.data.total;
              }else if (!data.phoneNumber1 && !data.phoneNumber2 && !data.email1
                && !data.email2 && data.lastName){
                const response = await getMatterListService.getLastNameCustomerMatterList(
                  this.customerLastName = data.lastName,
                  this.page = page,
                  Array.isArray(this.sortParam.sortBy)?this.sortParam.sortBy[0]:this.sortParam.sortBy,
                  (!this.sortParam.sortDesc || !this.sortParam.sortDesc[0]) ? 'asc' : 'desc'
                );
                // 取得したデータを格納
                this.matterList = response.data.data.data;
                this.pageCount = response.data.data.last_page;
                this.total = response.data.data.total;
              }else if (!data.phoneNumber1 && !data.phoneNumber2 && !data.email1
                && !data.email2 && !data.lastName && data.companyName){
                const response = await getMatterListService.getCompanyCustomerMatterList(
                  this.companyName = data.companyName,
                  this.page = page,
                  Array.isArray(this.sortParam.sortBy)?this.sortParam.sortBy[0]:this.sortParam.sortBy,
                  (!this.sortParam.sortDesc || !this.sortParam.sortDesc[0]) ? 'asc' : 'desc'
                );
                // 取得したデータを格納
                this.matterList = response.data.data.data;
                this.pageCount = response.data.data.last_page;
                this.total = response.data.data.total;
              }else {
                this.total = null
                this.matterList = []
                this.scrollDataList = []
              }
              // 回答期限の時分秒を切り捨てる
              if (this.matterList.length  > 0){
                this.matterList.forEach(item => {
                  this.scrollDataList.push(item);
                });
              }
              for (let i = 0; i < this.matterList.length; i++) {
                this.matterList[i].responseLimit = this.dateFormatChange(this.matterList[i].responseLimit);
              }
            } catch (e) {
              console.log(e);
            }
          this.$refs.CustomerInformationMatterListSearchTable.loadingCount--;
        },
        restoreState(){
          let matterState = this.$store.getters.getMatterState(this.matterStateKey);
          matterState.mountCompletion = true;
          delete matterState.scrollDataList;
          delete matterState.total;
          delete matterState.minimizeFlg;
          Object.assign(this.$data, matterState);
        },
        async saveState(data){
          let payload = { matterId: this.matterId, key: this.matterStateKey, data };
          this.$store.dispatch('saveMatterStateAction', payload)
        },
        // 入力クリアボタンイベント
        reset() {
            this.customerData.attentionFlg = null;
            this.matterCustomerData.id = null
            this.$refs.form.reset();
            this.updateCustomerDifferentDataList();
        },

        // 電話番号、郵便番号、FAX番号にもしハイフンが付いていたら削除する
        numberCheck(matterCustomerData) {
            if (matterCustomerData.phoneNumber1 !== null) {
                matterCustomerData.phoneNumber1 = matterCustomerData.phoneNumber1.split('-').join('');
            }
            if (matterCustomerData.phoneNumber2 !== null) {
              matterCustomerData.phoneNumber2 = matterCustomerData.phoneNumber2.split('-').join('');
            }
            if (matterCustomerData.postalCode !== null) {
                matterCustomerData.postalCode = matterCustomerData.postalCode.split('-').join('');
            }
            if (matterCustomerData.faxNumber !== null) {
                matterCustomerData.faxNumber = matterCustomerData.faxNumber.split('-').join('');
            }
          return matterCustomerData;
        },

        // 検索結果の決定処理
        // 検索結果モーダルより指定されたお客様のデータを取得し、すべてのテキストボックスに上書き
      getCustomerMasterData: async function (event, matterId) {
          // 「$event」に指定されたお客様のデータが格納されている
            // 電話番号、郵便番号、FAX番号にもしハイフンが付いていたら削除する
            this.numberCheck(event);
            // お客様情報を各変数に代入
            this.matterCustomerDataAssign(event);
            // お客様情報配列を更新
            this.updateCustomerData()
            // お客様情報を登録
            this.loadingCount++;
            try {
                if (matterId > 0) {
                  // DB更新(mattersテーブルにお客様idを追加)
                  await updateMatterService.updateMatterCustomerData(
                      matterId,
                      this.customerData,
                  ).then(async (response) => {
                    // 取得(更新)したデータを格納
                    const newMatterCustomerData = response.data.data.customer;
                    // 取得(更新)したデータで親コンポーネントの「matterCustomerData」を更新する
                    this.$emit('update:matterCustomerData', newMatterCustomerData);
                    // 取得(更新)したデータを各変数に代入
                    this.matterCustomerDataAssign(newMatterCustomerData);
                    await this.customerHistoryData(newMatterCustomerData);
                  }).catch(error => {
                    if(error.response.status === 403){
                      const editing_by = error.response.data.message.editing_by;
                      this.$emit('isEditing',editing_by);
                    }
                  });
                }else{
                  await this.customerCreate();
                }
            } catch (e) {
                console.log(e);
            }
            this.loadingCount--;
          this.searchResultModalFlg = false
        },
      customerServiceAddition: async function ($event, matterId) {
        this.$refs.searchConditionsModal.loadingCount++;
        this.loadingCount++;
        try {
          // 子コンポーネント側でダブルクリックされた行の商品情報を「$event」として受けとる
          // 登録商品が既に登録済みの場合、DB更新処理を行わない
          let productIdCheck = true;
          if (this.newMatterProductsData.id === $event.id) {
            productIdCheck = false;
          }
          // DB更新(商品登録)
          if (productIdCheck) {
            if (matterId > 0) {
              const response = await updateMatterService.updateProductData(
                matterId,
                $event.id
              );
              this.newMatterProductsData = response.data.data.product;
              // 親コンポーネント(InquiryHandle.vue)更新
              this.$emit('update:matterProductsData', this.newMatterProductsData);
            } else {
              await this.createProductData(
                $event.id);
            }
          }
          // 検索結果のモーダルを閉じる
          this.searchResulDialog = false;
          // 検索条件のモーダルを閉じる
          this.searchConditionsModal = false;
        } catch (e) {
          console.log(e);
        }
        this.$refs.searchConditionsModal.loadingCount--;
        this.loadingCount--;
      },
        searchConditionsModalOpen() {
          this.searchConditionsModal = true
        },
        searchConditionsModalClose() {
          this.searchConditionsModal = false
        },
        // api取得データを各変数に代入
        async matterCustomerDataAssign(matterCustomerData) {
            this.customerId = matterCustomerData.id;
            this.customerLastName = matterCustomerData.lastName;
            this.customerLastNameKana = matterCustomerData.lastNameKana;
            this.email1 = matterCustomerData.email1;
            this.email2 = matterCustomerData.email2;
            this.companyName = matterCustomerData.companyName;
            this.companyNameKana = matterCustomerData.companyNameKana;
            this.phoneNumber1 = matterCustomerData.phoneNumber1;
            this.phoneNumber2 = matterCustomerData.phoneNumber2;
            this.customerTypeFlg = matterCustomerData.customerTypeFlg;
            this.gender = matterCustomerData.gender;
            this.ageGroup = matterCustomerData.age_group;
            this.faxNumber = matterCustomerData.faxNumber;
            this.industryName = matterCustomerData.industryName;
            this.departmentName = matterCustomerData.departmentName;
            this.retailerName = matterCustomerData.retailerName;
            this.postalCode = matterCustomerData.postalCode;
            this.prefectureName = matterCustomerData.prefectureName;
            this.address = matterCustomerData.address;
            this.remarks = matterCustomerData.remarks;
            this.updatedAt = matterCustomerData.updatedAt;
            this.updatedBy = matterCustomerData.updatedBy;
            this.customerData.attentionFlg = matterCustomerData.attentionFlg;
        },
        async customerHistoryData(matterCustomerData){
          // 検索結果モーダルを閉じる
          this.scrollDataList = [];
          this.page = 1;
          await this.searchMatterList(matterCustomerData,this.page);
        },
        async saveAllMatter(matterId){
          let hasChanged = (this.customerLastName) || (this.customerLastNameKana) || (this.email1) || (this.email2) || (this.phoneNumber1)
                            || (this.phoneNumber2) || (this.customerTypeFlg != null) || (this.companyName) || (this.companyNameKana) || (this.gender != null) || (this.age_group != null)
                            || (this.faxNumber) || (this.industryName) || (this.departmentName) || (this.postalCode) || (this.prefectureName)
                            || (this.address) || (this.retailerName) || (this.remarks);
          if (hasChanged){
            await this.customerSave(matterId,1, false);
          }
        },
        // 入力した内容を保存ボタンクリック時
        customerSave: async function (matterId,paramsCheck = null, showLoading = true) {
          // 各変数の値をお客様情報配列に代入
          this.updateCustomerData();
          // DB更新
          if (showLoading)
            this.loadingCount++;
          try {
            if (matterId > 0) {
              await updateMatterService.updateMatterCustomerData(
                  matterId,
                  this.customerData,
              ).then(async response => {
                // 取得(更新)したデータを格納
                const newMatterCustomerData = response.data.data.customer;
                // 取得(更新)したデータで親コンポーネントの「matterCustomerData」を更新する
                this.$emit('update:matterCustomerData', newMatterCustomerData);
                // 取得(更新)したデータを各変数に代入
                this.matterCustomerDataAssign(newMatterCustomerData);
                this.customerHistoryData(newMatterCustomerData);
                if (paramsCheck == 0){
                  // attentionFlg
                  this.notificationAttentionsFlg = true;
                }else if (paramsCheck == 1){
                  // update
                }
              }).catch(error => {
                if(error.response.status === 403){
                  const editing_by = error.response.data.message.editing_by;
                  this.$emit('isEditing',editing_by);
                }
              });

            }else{
              await this.customerCreate();
            }
          } catch (e) {
              console.log(e);
          }

          if (showLoading)
            this.loadingCount--;
        },
        closeModalNotificationsAttentionFlg(){
          this.notificationAttentionsFlg = false;
        },
        customerClone: async function (matterId) {
          this.$refs.idCustomerClear.reset();
          // 各変数の値をお客様情報配列に代入
          this.updateCustomerData();
          // DB更新
          this.loadingCount++;
          try {
            const response = await updateMatterService.updateMatterCustomerData(
              matterId,
              this.customerData,
            );
            const newMatterCustomerData = response.data.data.customer;
            this.$emit('update:matterCustomerData', newMatterCustomerData);
            this.matterCustomerDataAssign(newMatterCustomerData);
            await this.customerHistoryData(newMatterCustomerData);
          }catch (e) {
              console.log(e);
            }
          this.loadingCount--;
          },
        //
        attentionCustomerSave: async function (matterId) {
          // 各変数の値をお客様情報配列に代入
          this.customerData.attentionFlg = 0;
          // DB更新
          await this.customerSave(matterId,0)
        },
        customerCreate: async function () {
          const response = await updateMatterService.createMatterCustomerData(
              this.customerData,
          );
          // 取得(更新)したデータを格納
          const newMatter = response.data.data;
          this.customerId = newMatter.customer.id;
          this.$emit('create:matterCustomer', newMatter);
        },
        // ルートが変更された時に呼び出す関数(この関数がないと/inquiryhandle/1から/inquiryhandle/2に変わった時にコンポーネントが更新されない)
        reloadCustomerInformation: async function () {
            try {
                // 電話番号、郵便番号、FAX番号にもしハイフンが付いていたら削除する
                this.matterCustomerData = this.numberCheck(this.matterCustomerData)
                // api取得データを各変数に代入
                this.matterCustomerDataAssign(this.matterCustomerData);
                await this.customerHistoryData(this.matterCustomerData);
                // テキストボックスの内容が変更されたタイミングで親(InquiryHandle.vue)の「customerDifferentDataLis」を更新する
                this.updateCustomerDifferentDataList();
            } catch (e) {
                console.log(e);
            }
        },
        // テキストボックスの内容が変更されたタイミングで親(InquiryHandle.vue)の「customerDifferentDataList(DBとテキストでデータが異なる項目リスト)」を更新する
        updateCustomerDifferentDataList() {
            // DBの値と現在の値が違う項目のリストを更新
            this.updateDifferentDataList();
            // console.log("保存されていない変更項目リストの更新");
            this.$emit('update:customerDifferentDataList', this.differentDataList);
        },
        changePostalCode(){
          this.prefectureName = '';
          this.address = '';
          let root = this;
          postal_code.get(this.postalCode, function(address) {
            if (address){
              if (address.prefecture){
                root.prefectureName = address.prefecture;
              } else{
                root.prefectureName = '';
              }
              let ad = '';
              if (address.city) ad = address.city;
              if (address.area) ad += address.area;
              if (address.street) ad += address.street;
              root.address = ad;
            }
          });
        },
        // 匿名Swichのイベントハンドラ - ON時：名前を'匿名'と表示する
        anonymousSwichEvnet() {
            if (this.anonymous) {
                this.reset()
                this.customerLastName = '匿名'
                this.customerLastNameKana = 'とくめい'
            } else {
                this.customerLastName = ''
                this.customerLastNameKana = ''
            }
        },
        // 各変数の値をお客様情報配列に代入
        updateCustomerData() {
            this.customerData.id = this.customerId;
            this.customerData.lastName = this.customerLastName;
            this.customerData.lastNameKana = this.customerLastNameKana;
            this.customerData.email1 = this.email1;
            this.customerData.email2 = this.email2;
            this.customerData.phoneNumber1 = this.phoneNumber1;
            this.customerData.phoneNumber2 = this.phoneNumber2;
            this.customerData.customerTypeFlg = this.customerTypeFlg;
            this.customerData.companyName = this.companyName;
            this.customerData.companyNameKana = this.companyNameKana;
            this.customerData.gender = this.gender;
            this.customerData.age_group = this.ageGroup;
            this.customerData.faxNumber = this.faxNumber;
            this.customerData.industryName = this.industryName;
            this.customerData.departmentName = this.departmentName;
            this.customerData.postalCode = this.postalCode;
            this.customerData.prefectureName = this.prefectureName;
            this.customerData.address = this.address;
            this.customerData.retailerName = this.retailerName;
            this.customerData.remarks = this.remarks;
        },
        // DBの値と現在の値が違う項目のリストを更新(状態パネルの「問い合わせを閉じる」ボタンのモーダルに使用する)
        updateDifferentDataList() {
            // 各変数の値をお客様情報配列に代入
            this.updateCustomerData();
            // 配列の要素をすべて削除
            this.differentDataList.splice(0);

            for (let key in this.customerData) {
                if (this.matterCustomerData[key] !== this.customerData[key]) {
                    // 値がnullの項目に何か値を入力して消すと「''」になるので、その場合はpushしない
                    if (this.matterCustomerData[key] === null && this.customerData[key] === '') {
                        continue;
                    }
                    // 配列に追加
                    this.differentDataList.push(
                        {
                            title: key,
                            changeBackText: this.matterCustomerData[key],
                            changeText: this.customerData[key]
                        }
                    );
                }
            }
        },
      updateBackgroundColor(backgroundColorCustomer) {
        const data = {
          background_customer: backgroundColorCustomer,
        }
        this.$store.commit('updateBackgroundColorCustomer', backgroundColorCustomer);
        userSettingService.updateMyUserSettings(data);
      },
      resetBackgroundColor() {
        const data = {
          background_customer: this.defaultBackgroundColor,
        }
        this.$store.commit('updateBackgroundColorCustomer', this.defaultBackgroundColor);
        userSettingService.updateMyUserSettings(data);
        this.closeColorPickerBackgroundModal();
      },
      openColorPickerBackgroundModal() {
        if (this.$store.state.backgroundColorCustomer == null){
          this.backgroundColor = this.defaultBackgroundColor
        }else {
          this.backgroundColor =  this.$store.state.backgroundColorCustomer
        }
        this.colorPickerBackground = true
      },
      closeColorPickerBackgroundModal() {
        this.colorPickerBackground = false
      },
    },
    computed: {
        // お客様特定flg
        customerIdCheck() {
            if (this.matterCustomerData.id === null) {
                return false;
            } else {
                return true;
            }
        },
        // バックエンド_お客様検索apiに渡すメールアドレス
        email() {
            if (this.email1 === null && this.email2 === null) {
                return '';
            } else if (this.email1 !== null && this.email2 === null) {
                return this.email1;
            } else if (this.email1 === null && this.email2 !== null) {
                return this.email2;
            } else {
                return [this.email1, this.email2];
            }
        },
        // バックエンド_お客様検索apiに渡す電話番号
        phoneNumber() {
            if (this.phoneNumber1 === null && this.phoneNumber2 === null) {
                return '';
            } else if (this.phoneNumber1 !== null && this.phoneNumber2 === null) {
                return this.phoneNumber1;
            } else if (this.phoneNumber1 === null && this.phoneNumber2 !== null) {
                return this.phoneNumber2;
            } else {
                return [this.phoneNumber1, this.phoneNumber2];
            }
        },
        matterStateKey(){
          return `matter_state_${this.matterId}_customer`;
        }
    },
    mounted() {
        // 電話番号、郵便番号、FAX番号にもしハイフンが付いていたら削除する
        this.matterCustomerData = this.numberCheck(this.matterCustomerData)
        // api取得データを各変数に代入
        this.matterCustomerDataAssign(this.matterCustomerData);
        this.customerHistoryData(this.matterCustomerData);
        this.mountCompletion = true;
        this.restoreState();
    },
    beforeUpdate() {
        // console.log("データの更新がされました")
        // テキストボックスの内容が変更されたタイミングで親(InquiryHandle.vue)の「customerDifferentDataLis」を更新する
        this.updateCustomerDifferentDataList();
        if (this.matterId <= 0){
          this.total = null
          this.scrollDataList = []
        }
    },
    // updated() {
    //     console.log("DOMが更新されました(変更リストの更新)")

    // }
}
</script>
<style>
.v-radio .v-icon {
  border-radius: 50%;
  background-image: radial-gradient(white 50%, transparent 50%);
}
.text-overflow-content{
  --lineHeight: 92.5px!important;
}
.hide-text{
  --lineHeight: 0px!important;
}
.v-application .mb-3{
  margin-bottom: 0px!important;
}
</style>
